import React from 'react'
import classes from './MainSection.module.scss';
import { Link } from 'react-router-dom';
import marketplaceLogo from '../../images/marketplace.svg'
import examLogo from '../../images/exam.svg'

const MainSection = () => {
  return (
    <div className={classes.section}>
      
      <Link to='/marketplace' >
        <div className={classes.section__card}>
            <img src={marketplaceLogo} alt='Marketplace' />
            <p className={classes.section__card__header}>Marketplase</p>
            <p className={classes.section__card__body}>Cars, plates, stickers, drivers, and auto accessories. Find your needs easily.</p>
        </div>
      </Link>

      <Link to='/exam' >
        <div className={classes.section__card}>
            <img src={examLogo} alt='Exam' />
            <p className={classes.section__card__header}>Practice Exam</p>
            <p className={classes.section__card__body}>Pass the TLC exam easily! Train whenever you can and start earning faster.</p>
        </div>
      </Link>

    </div>
  )
}

export default MainSection