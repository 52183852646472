import React from 'react';

import classes from './SavedQuestionItem.module.scss';

import trashIcon from './../../images/trash.svg';

const SavedQuestionItem = ({handleDelete, questionInfo}) => {

  return (
    <div className={classes.itemBlock}>
        <p className={classes.itemBlock_contentSection}>{questionInfo.question}</p>
        
        <div className={classes.itemBlock_iconSection}>
            <img src={trashIcon} alt="Trash"
            onClick={handleDelete} 
            />
        </div>
    </div>
  )
}

export default SavedQuestionItem