import React from 'react';

import amazonImg from '../images/amazon_full.png';

import classes from './ButtonAmazon.module.scss';

const ButtonAmazon = ({redirectURL}) => {
  return (
    <div className={classes.container}>
        <a href={redirectURL} target="_blank" rel="noreferrer">
            <img src={amazonImg} alt="Amazon"/>
        </a>
    </div>
  )
}

export default ButtonAmazon