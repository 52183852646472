import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import ENV from "../../env";

import classes from "./CartSummary.module.scss";
import { AccountContext } from "../auth/account";
import Spinner from "../Spinner";
import PopupSetupIntent from "../dashboard/payments/PopupSetupIntent";

import { useSelector } from "react-redux";

const CartSummary = (props) => {
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { getSession } = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(true);

  const cartTotalPrice = useSelector((state) => state.cart.cartTotalPrice);
  const shippingPrice = useSelector((state) => state.cart.shippingPrice);

  useEffect(() => {
    props.setPaymentAmount(
      (shippingPrice + cartTotalPrice + cartTotalPrice * 0.08875).toFixed(2)
    );
    // eslint-disable-next-line
  }, [cartTotalPrice]);

  useEffect(() => {
    // Fetch user payment methods
    getSession()
      .then((session) => {
        axios
          .get(ENV.BASE_URL + "/payments/payment-methods", {
            headers: { Authorization: `Bearer ${session.idToken.jwtToken}` },
          })
          .then((response) => {
            // Check if request is success
            if (response.status === 200) {
              setPaymentMethod(response.data.data);
              setIsLoading(false);
            } else {
              console.log("[ERROR]: Error happens while fetching user info.");
            }
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [getSession]);

  useEffect(() => {
    if (selectedPayment) {
      props.setSelectedPM(selectedPayment);
    }
  }, [selectedPayment, props]);

  return (
    <div className={classes.container}>
      <p className={classes.title}>Order summary</p>
      <div className={classes.order_info}>
        <div className={classes.order_info_row}>
          <p>Subtotal:</p>
          <p>
            <span>$</span>
            {parseFloat(cartTotalPrice).toFixed(2)}
          </p>
        </div>
        <div className={classes.order_info_row}>
          <p>Estimated tax:</p>
          <p>
            <span>$</span>
            {parseFloat(cartTotalPrice * 0.08875).toFixed(2)}
          </p>
        </div>
        <div className={classes.order_info_row}>
          <p>Shipping:</p>
          <p>
            <span>$</span>
            {parseFloat(shippingPrice).toFixed(2)}
          </p>
        </div>
        <hr className={classes.divider} />
        <div className={classes.order_info_row}>
          <p>Order total:</p>
          <p>
            <span>$</span>
            {parseFloat(shippingPrice + cartTotalPrice + cartTotalPrice * 0.08875).toFixed(2)}
          </p>
        </div>
      </div>

      <p className={classes.title}>Payments</p>
      {/* Show loading spinner while fetching data */}
      {isLoading && <Spinner />}

      {!isLoading && (
        <div className={classes.payments}>
          {paymentMethod.map((card, i) => (
            <div
              className={classes.order_info_row}
              key={i}
              onClick={() => setSelectedPayment(card.id)}
            >
              <input
                type="radio"
                checked={selectedPayment === card.id}
                value={card.id}
                name="payment"
                readOnly={true}
              />{" "}
              <p>
                {" "}
                **** {card.last4}{" "}
                <span style={{ marginLeft: "2rem" }}>
                  {card.exp_month}/{card.exp_year}
                </span>
              </p>
            </div>
          ))}

          {isPopupOpen && (
            <PopupSetupIntent
              fromURL="cart"
              handleClose={() => {
                setIsPopupOpen(false);
              }}
            />
          )}
        </div>
      )}
      <div className={classes.payments__buttonBlock}>
        <button
          onClick={() => {
            setIsPopupOpen(true);
          }}
        >
          Add payment method
        </button>
      </div>
    </div>
  );
};

export default CartSummary;
