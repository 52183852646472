import React from 'react'
import classes from './WelcomeSection.module.scss';
import heroImg from '../../images/hero.svg';


const WelcomeSection = () => {

  // Scroll to Contact section
  const scrollToContactSection = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  return (
    <div className={classes.section}>

        <div className={classes.section__imageSide}>
            <img src={heroImg} alt='HeroImg'></img>
        </div>

        <div className={classes.section__contentSide}>
            <h1>EVERYTHING YOU NEED FOR YOUR TLC DRIVER CAREER IN ONE PLACE</h1>

            <ul>
              <li>Up-to-date TLC Marketplace</li>
              <li>Practice exam is close to a real one</li>
              <li>Detailed career path</li>
              <li>Best customer support 24/7 for clients</li>
            </ul>

            <p>Looking more? Please contact us</p>
            <button onClick={scrollToContactSection}>Contact Us</button>
        </div>

    </div>
  )
}

export default WelcomeSection