import React from 'react'
import { ToastContainer } from 'react-toastify';

import classes from './Layout.module.scss';
import 'react-toastify/dist/ReactToastify.css';
  // minified version is also included
  // import 'react-toastify/dist/ReactToastify.min.css';

import Header from './Header'
import Footer from './Footer';

const Layout = ({children}) => {
  return (
    <>
        <Header />
        <div className={classes.container}>
          <ToastContainer />
          {children}
          <Footer />
        </div>
    </>
  )
}

export default Layout