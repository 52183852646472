import { createSlice } from "@reduxjs/toolkit";

export const listingFilterSlice = createSlice({
  name: "listingFilters",
  initialState: {
    filters: {},
  },
  reducers: {
    // Store user listing filters
    setListingFilter: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const { setListingFilter } = listingFilterSlice.actions;

export default listingFilterSlice.reducer;
