import React from 'react';

import classes from './StepProgressStatusBar.module.scss';

const StepProgressStatusBar = ({currentStep, setStep}) => {
  
  return (
    <div className={classes.container}>

      {/* Step 1 */}
      <div className={classes.container__block}>
        <div className={classes.container__block__top}>
          <div className={classes.container__block__top__line}>
          </div>
          <div 
            className={`${classes.container__block__top__circle} 
            ${currentStep >= 1 ? classes.circle_selected : " "}`}
            onClick={()=>{setStep(1)}} >
              <p>1</p>
          </div>
          <div className={classes.container__block__top__line}>
          </div>
        </div>
      </div>
      
      {/* Step 2 */}
      <div className={classes.container__block}>
        <div className={classes.container__block__top}>
          <div className={classes.container__block__top__line}>
          </div>
          <div 
            className={`${classes.container__block__top__circle} 
            ${currentStep >= 2 ? classes.circle_selected : " "}`}
            onClick={()=>{setStep(2)}} >
              <p>2</p>
          </div>
          <div className={classes.container__block__top__line}>
          </div>
        </div>
      </div>

      {/* Step 3 */}
      <div className={classes.container__block}>
        <div className={classes.container__block__top}>
          <div className={classes.container__block__top__line}>
          </div>
          <div 
            className={`${classes.container__block__top__circle} 
            ${currentStep >= 3 ? classes.circle_selected : " "}`}
            onClick={()=>{setStep(3)}} >
              <p>3</p>
          </div>
          <div className={classes.container__block__top__line}>
          </div>
        </div>
      </div>

      {/* Step 4 */}
      <div className={classes.container__block}>
        <div className={classes.container__block__top}>
          <div className={classes.container__block__top__line}>
          </div>
          <div 
            className={`${classes.container__block__top__circle} 
            ${currentStep >= 4 ? classes.circle_selected : " "}`}
            onClick={()=>{setStep(4)}} >
              <p>4</p>
          </div>
          <div className={classes.container__block__top__line}>
          </div>
        </div>
      </div>

      {/* Step 5 */}
      <div className={classes.container__block}>
        <div className={classes.container__block__top}>
          <div className={classes.container__block__top__line}>
          </div>
          <div 
            className={`${classes.container__block__top__circle} 
            ${currentStep >= 5 ? classes.circle_selected : " "}`}
            onClick={()=>{setStep(5)}} >
              <p>5</p>
          </div>
          <div className={classes.container__block__top__line}>
          </div>
        </div>
      </div>

      {/* Step 6 */}
      <div className={classes.container__block}>
        <div className={classes.container__block__top}>
          <div className={classes.container__block__top__line}>
          </div>
          <div 
            className={`${classes.container__block__top__circle} 
            ${currentStep >= 6 ? classes.circle_selected : " "}`}
            onClick={()=>{setStep(6)}} >
              <p>6</p>
          </div>
          <div className={classes.container__block__top__line}>
          </div>
        </div>
      </div>

      {/* Step 7 */}
      <div className={classes.container__block}>
        <div className={classes.container__block__top}>
          <div className={classes.container__block__top__line}>
          </div>
          <div 
            className={`${classes.container__block__top__circle} 
            ${currentStep >= 7 ? classes.circle_selected : " "}`}
            onClick={()=>{setStep(7)}} >
              <p>7</p>
          </div>
          <div className={classes.container__block__top__line}>
          </div>
        </div>
      </div>

      {/* Step 8 */}
      <div className={classes.container__block}>
        <div className={classes.container__block__top}>
          <div className={classes.container__block__top__line}>
          </div>
          <div 
            className={`${classes.container__block__top__circle} 
            ${currentStep >= 8 ? classes.circle_selected : " "}`}
            onClick={()=>{setStep(8)}} >
              <p>8</p>
          </div>
          <div className={classes.container__block__top__line}>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default StepProgressStatusBar