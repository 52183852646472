import React, { useContext, useEffect, useRef } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";

import classes from "./OrderList.module.scss";

import OrderItem from "./OrderItem";
import { AccountContext } from "./../auth/account";
import {
  resetCartTotalPrice,
  addToCartTotalPrice,
} from "./../../redux/slices/cartSlice";
import ENV from "../../env";

const OrderList = ({
  orderList,
  setOrderList,
  selectedOrders,
  setSelectedOrders,
}) => {
  const { getSession } = useContext(AccountContext);
  const isInit = useRef(true);

  const dispatch = useDispatch();

  // Count total cart amout
  useEffect(() => {
    if (isInit.current) {
      isInit.current = false;
      dispatch(resetCartTotalPrice());

      // Check for empty order list
      if (orderList.length !== 0) {
        dispatch(
          addToCartTotalPrice(
            orderList
              .map((item) => item.quantity * item.price)
              .reduce((prev, next) => prev + next)
          )
        );
      }

      // Add all products to selected list
      setSelectedOrders(
        orderList.map((order) => {
          return order.id;
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  const onProductUpdataHandler = (objToUpdata) => {
    // Increment product quantity
    if (objToUpdata.type === "INCREMENT") {
      // Increment quantity in DB
      getSession().then((session) => {
        axios
          .patch(
            ENV.BASE_URL + "/users/cart",
            {
              "cart-product-id": objToUpdata.id,
              quantity: objToUpdata.quantity + 1,
            },
            {
              headers: {
                Authorization: `Bearer ${session.idToken.jwtToken}`,
              },
            }
          )
          .then(() => {
            const updatedOrderList = orderList.map((order) => {
              if (order.id === objToUpdata.id) {
                return { ...order, quantity: order.quantity + 1 };
              }

              return order;
            });
            setOrderList(updatedOrderList);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    } else if (objToUpdata.type === "DECREMENT") {
      // Decrement quantity in DB
      getSession().then((session) => {
        axios
          .patch(
            ENV.BASE_URL + "/users/cart",
            {
              "cart-product-id": objToUpdata.id,
              quantity: objToUpdata.quantity - 1,
            },
            {
              headers: {
                Authorization: `Bearer ${session.idToken.jwtToken}`,
              },
            }
          )
          .then(() => {
            // Decrement product quantity
            const updatedOrderList = orderList.map((order) => {
              if (order.id === objToUpdata.id) {
                return { ...order, quantity: order.quantity - 1 };
              }

              return order;
            });
            setOrderList(updatedOrderList);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    } else if (objToUpdata.type === "DELETE") {
      getSession().then((session) => {
        axios
          .delete(
            ENV.BASE_URL + "/users/cart?cart-product-id=" + objToUpdata.id,
            {
              headers: {
                Authorization: `Bearer ${session.idToken.jwtToken}`,
              },
            }
          )
          .then(() => {
            // Delete product from cart
            setOrderList(
              orderList.filter((order) => order.id !== objToUpdata.id)
            );
          })
          .catch((err) => {
            console.log(err);
          });
      });
    } else if (objToUpdata.type === "SELECT_ORDER") {
      setSelectedOrders([...selectedOrders, objToUpdata.id]);
    } else if (objToUpdata.type === "UNSELECT_ORDER") {
      setSelectedOrders(
        selectedOrders.filter((order) => order !== objToUpdata.id)
      );
    }
  };

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>Order details</h2>
      {orderList.map((order) => {
        return (
          <OrderItem
            order={order}
            updateItem={(objToUpdata) => {
              onProductUpdataHandler(objToUpdata);
            }}
            key={order.id}
          />
        );
      })}
    </div>
  );
};

export default OrderList;
