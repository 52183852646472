import React from 'react';

import classes from './UserInfo.module.scss';

import userLogo from '../../images/taxi-driver.png';
import verified from '../../images/verified.png';
import { Link } from 'react-router-dom';

const UserInfo = ({userData}) => {
  
  return (
    <div className={classes.container}>
      <div className={classes.container_infoBlock}>
        <div className={classes.container_infoBlock_image}>
          <img src={userLogo} alt="Images"/>
        </div>
        <div className={classes.container_infoBlock_content}>
          <h3>{userData.full_name}</h3>
          <div className={classes.container_infoBlock_content_row}>
            <img src={verified} alt="verified icon"/>
            <p>{userData.email}</p>
          </div>
        </div>
      </div>

      <div className={classes.container_docsBlock}>
        {/* <p>Some user stats comming here (active ads)</p> */}
        
        <Link to='/create-listing'>
          <button>+ New Listing</button>
        </Link>
      </div>

    </div>
  )
}

export default UserInfo