import React, { useState } from "react";
import axios from "axios";
import classes from "./GetInTouch.module.scss";
import phoneIcon from "../../images/phone.svg";
import emailIcon from "../../images/email.svg";
import ENV from "./../../env";

const GetInTouch = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    // Send API request
    axios
      .post(ENV.BASE_URL + "/contact-us", {
        name: name,
        email: email,
        message: msg,
      })
      .then(function (response) {
        if (response.status === 200) {
          // Clear inputs
          setName("");
          setEmail("");
          setMsg("");
          setErrorMsg("");
          setIsSent(true);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        setErrorMsg(error.response.data.error);
        setIsLoading(false);
      });
  };

  return (
    <div className={classes.section}>
      <h1>Get in touch</h1>

      <div className={classes.section__content}>
        <form
          className={classes.section__content__contentForm}
          onSubmit={handleSubmit}
        >
          <h2>Contact us</h2>

          <input
            placeholder="Name"
            type="text"
            value={name}
            minLength="2"
            required
            onChange={(e) => setName(e.target.value)}
          />

          <input
            type="email"
            placeholder="Email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <textarea
            placeholder="Message"
            required
            value={msg}
            onChange={(event) => {
              setMsg(event.target.value);
            }}
          />

          {/* Show  msg after submiting form */}
          {isSent && (
            <p>
              Thank you for your question. Our team will reach out to you soon.
            </p>
          )}

          {/* Show error msg */}
          {errorMsg.length > 1 && <p className={classes.failed}>{errorMsg}</p>}

          {!isLoading ? <input type="submit" /> : <p>Loading...</p>}
        </form>

        <div className={classes.section__content__contentText}>
          <div className={classes.section__content__contentText__contactInfo}>
            <div className={classes.section__content__contentText__block}>
              <img src={emailIcon} alt="Email" />
              <p>contact@drivetlc.com</p>
            </div>

            <div className={classes.section__content__contentText__block}>
              <img src={phoneIcon} alt="Phone" />
              <p>+1 631 948 5635</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
