import React, { useState, useContext, useEffect, useMemo } from "react";
import { AccountContext } from "../auth/account";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import debounce from "lodash.debounce";
import ENV from "../../env";

import classes from "./CreateListing.module.scss";
import editIcon from "../../images/icons/pencil.svg";
import Spinner from "../Spinner";

import carDefaultImage from "../../images/listing_default_car.svg";
import platesDefaultImage from "../../images/listing_default_plates.png";
import logoAdd from "../../images/icons/add_image.svg";
import SmallImageCard from "./SmallImageCard";
import CarListingInfo from "./CarListingInfo";
import CarDetailBlock from "./CarDetailBlock";
import PlateListingInfo from "./PlateListingInfo";

const CreateListing = () => {
  // TODO 1. Collect listing info
  // 2. Preview listing
  // 3. Pay for listing
  // 4. Show moderation msg

  //   const [isLoading, setIsLoading] = useState(false);
  const { getSession } = useContext(AccountContext);
  const [isLogedIn, setIsLogedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [category, setCategory] = useState({});
  const [userData, setUserData] = useState("");
  const [showNameInput, setShowNameInput] = useState(false);
  const [userName, setUserName] = useState("");
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [showPhoneInput, setShowPhoneInput] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [userLocation, setUserLocation] = useState("");
  const [userLocationObj, setUserLocationObj] = useState({});
  const [stepOneError, setStepOneError] = useState(false);
  const [stepOneErrorMsg, setStepOneErrorMsg] = useState([]);
  const [isSavingStepOne, setIsSavingStepOne] = useState(false);
  const [stepTwoError, setStepTwoError] = useState(false);
  const [stepTwoErrorMsg, setStepTwoErrorMsg] = useState([]);
  const [isSavingStepTwo, setIsSavingStepTwo] = useState(false);
  const [listingId, setListingId] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [fileImages, setFileImages] = useState([]);
  const [listingBase64, setListingBase64] = useState([]);
  const [listingCarInfo, setListingCarInfo] = useState({
    title: "",
    carNumber: "",
    description: "",
    listingPrice: "",
    paymentPeriod: "",
  });
  const [listingPlateInfo, setListingPlateInfo] = useState({ carNumber: "" });
  const [listingCarDetail, setListingCarDetail] = useState({});
  const [readyToSendImages, setReadyToSendImages] = useState(false);
  const [isSuggestedSearching, setIsSuggestedSearching] = useState(false);
  const [suggestedSearch, setSuggestedSearch] = useState([]);
  const [isSelectedLocation, setIsSelectedLocation] = useState(false);

  const maxImagesLimit = 9;
  const categoryOptions = [
    { value: "cars", label: "Cars" },
    { value: "plates", label: "Plates" },
    // { value: "drivers", label: "Drivers" },
  ];

  const navigate = useNavigate();

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      // backgroundColor: "red" ,
      // color: "#FFF",
      fontSize: 18,
      border: 1,
      boxShadow: "none",
    }),
    menuList: (styles) => ({
      ...styles,
      // background: 'papayawhip',
      // width: 155
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused ? "#f6f6f6" : isSelected ? "#f6f6f6" : undefined,
      color: "#1d1d1d",
      zIndex: 1,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 1,
      // color: 'white'
    }),
    singleValue: (provided) => ({ ...provided, color: "#1d1d1d" }),

    dropdownIndicator: (base) => ({
      ...base,
      color: "#1d1d1d",
    }),
  };

  useEffect(() => {
    getSession()
      .then((session) => {
        setIsLogedIn(true);
        axios
          .get(ENV.BASE_URL + "/users", {
            headers: { Authorization: `Bearer ${session.idToken.jwtToken}` },
          })
          .then((response) => {
            // Check if request is success
            if (response.status === 200) {
              setUserData(response.data);

              // Set defaults values for listing
              setUserName(response.data.full_name);
              setUserEmail(response.data.email);
              setUserPhoneNumber(
                response.data.phone ? response.data?.phone : ""
              );

              // Disable spiner
              setIsLoading(false);
            } else {
              console.log("[ERROR]: Error happens while fetching user info.");
              navigate("/");
            }
          });
      })
      .catch((err) => {
        navigate("/");
      });
  }, [getSession, navigate]);

  useEffect(() => {
    if (readyToSendImages) {
      if (category.value === "cars") {
        saveStepTwoDraft();
      } else if (category.value === "plates") {
        saveStepTwoPlateDraft();
      }
    }
    // eslint-disable-next-line
  }, [readyToSendImages]);

  function checkAndSaveStepOneDraft() {
    // Check listing category
    new Promise((resolve) => {
      if (Object.keys(category).length === 0) {
        if (!stepOneErrorMsg.includes("Category not selected")) {
          setStepOneError(true);
          setStepOneErrorMsg((errors) => [...errors, "Category not selected"]);
        }
        resolve(false);
      } else {
        setStepOneErrorMsg((errors) =>
          errors.filter((element) => element !== "Category not selected")
        );
        resolve(true);
      }
    }).then((categoryResult) => {
      if (categoryResult) {
        new Promise((resolve) => {
          if (userLocation.length < 3) {
            if (!stepOneErrorMsg.includes("Location not entered")) {
              setStepOneError(true);
              setStepOneErrorMsg((errors) => [
                ...errors,
                "Location not entered",
              ]);
            }
            resolve(false);
          } else {
            setStepOneErrorMsg((errors) =>
              errors.filter((element) => element !== "Location not entered")
            );
            resolve(true);
          }
        }).then((locationResult) => {
          if (locationResult) {
            // Save draft listing
            saveStepOneDraft();
          }
        });
      }
    });
  }

  function saveStepOneDraft() {
    // Save data and move to the next stap
    setIsSavingStepOne(true);
    getSession()
      .then((session) => {
        // Deactivate button
        axios({
          method: "post",
          url: ENV.BASE_URL + "/listing/save-draft",
          headers: {
            Authorization: `Bearer ${session.idToken.jwtToken}`,
          },
          data: {
            step: 1,
            listing_id: listingId,
            category: category.value,
            name: userName,
            email: userEmail,
            phone_number: userPhoneNumber,
            location: JSON.stringify(userLocationObj),
          },
        })
          .then((response) => {
            // Check if request is success
            if (response.status === 200) {
              setListingId(response.data.data.listing_id);

              // Change to next step
              setStep(2);
            } else {
              console.log(
                "[ERROR]: Error happens while saving user listing draft."
              );
            }
            // Show button
            setIsSavingStepOne(false);
          })
          .catch((err) => {
            console.log(
              "[ERROR]: Error happens while saving user listing draft."
            );

            setIsSavingStepOne(false);
          });
      })
      .catch((err) => {
        console.log(err.response.message);
        console.log("[ERROR]: Error happens while fetching user info.");
        setIsSavingStepOne(false);
      });
  }

  async function checkAndSaveStepTwoDraft() {
    if (
      listingCarInfo?.title &&
      listingCarInfo?.listingPrice &&
      listingCarInfo?.paymentPeriod &&
      listingCarDetail?.make &&
      listingCarDetail?.model &&
      listingCarDetail?.year
    ) {
      setStepTwoError(false);
      setStepTwoErrorMsg([]);
      setIsSavingStepTwo(true);

      // Convert images to Base64
      if (fileImages.length) {
        for (let index = 0; index < fileImages.length; index++) {
          await fileToBase64(fileImages[index], index, fileImages.length - 1);
        }
      } else {
        setReadyToSendImages(true);
      }
    } else {
      setStepTwoError(true);

      // Check title
      if (!listingCarInfo?.title) {
        if (!stepTwoErrorMsg.includes("Listing title is not entered")) {
          setStepTwoErrorMsg((errors) => [
            ...errors,
            "Listing title is not entered",
          ]);
        }
      } else {
        // Remove title error if present
        setStepTwoErrorMsg((errors) =>
          errors.filter((element) => element !== "Listing title is not entered")
        );
      }

      // Check price
      if (!listingCarInfo?.listingPrice) {
        if (!stepTwoErrorMsg.includes("Listing price is not entered")) {
          setStepTwoErrorMsg((errors) => [
            ...errors,
            "Listing price is not entered",
          ]);
        }
      } else {
        // Remove price error if present
        setStepTwoErrorMsg((errors) =>
          errors.filter((element) => element !== "Listing price is not entered")
        );
      }

      // Check payment period
      if (!listingCarInfo?.paymentPeriod) {
        if (
          !stepTwoErrorMsg.includes("Listing payment period is not entered")
        ) {
          setStepTwoErrorMsg((errors) => [
            ...errors,
            "Listing payment period is not entered",
          ]);
        }
      } else {
        // Remove price error if present
        setStepTwoErrorMsg((errors) =>
          errors.filter(
            (element) => element !== "Listing payment period is not entered"
          )
        );
      }

      // Check make
      if (!listingCarDetail?.make) {
        if (!stepTwoErrorMsg.includes("Make is not entered")) {
          setStepTwoErrorMsg((errors) => [...errors, "Make is not entered"]);
        }
      } else {
        // Remove make error if present
        setStepTwoErrorMsg((errors) =>
          errors.filter((element) => element !== "Make is not entered")
        );
      }

      // Check model
      if (!listingCarDetail?.model) {
        if (!stepTwoErrorMsg.includes("Model is not entered")) {
          setStepTwoErrorMsg((errors) => [...errors, "Model is not entered"]);
        }
      } else {
        // Remove model error if present
        setStepTwoErrorMsg((errors) =>
          errors.filter((element) => element !== "Model is not entered")
        );
      }

      // Check year
      if (!listingCarDetail?.year) {
        if (!stepTwoErrorMsg.includes("Year is not entered")) {
          setStepTwoErrorMsg((errors) => [...errors, "Year is not entered"]);
        }
      } else {
        // Remove year error if present
        setStepTwoErrorMsg((errors) =>
          errors.filter((element) => element !== "Year is not entered")
        );
      }

      // If no error deactivate error block
      if (stepTwoErrorMsg.length === 0) {
        setStepOneError(false);
      }
    }
  }

  // Check Plate listing
  async function checkAndSaveStepTwoPlateDraft() {
    if (
      listingPlateInfo?.title &&
      listingPlateInfo?.listingPrice &&
      listingPlateInfo?.paymentPeriod
    ) {
      setStepTwoError(false);
      setStepTwoErrorMsg([]);
      setIsSavingStepTwo(true);

      // Convert images to Base64
      if (fileImages.length) {
        for (let index = 0; index < fileImages.length; index++) {
          await fileToBase64(fileImages[index], index, fileImages.length - 1);
        }
      } else {
        setReadyToSendImages(true);
      }
    } else {
      setStepTwoError(true);

      // Check title
      if (!listingPlateInfo?.title) {
        if (!stepTwoErrorMsg.includes("Listing title is not entered")) {
          setStepTwoErrorMsg((errors) => [
            ...errors,
            "Listing title is not entered",
          ]);
        }
      } else {
        // Remove title error if present
        setStepTwoErrorMsg((errors) =>
          errors.filter((element) => element !== "Listing title is not entered")
        );
      }

      // Check price
      if (!listingPlateInfo?.listingPrice) {
        if (!stepTwoErrorMsg.includes("Listing price is not entered")) {
          setStepTwoErrorMsg((errors) => [
            ...errors,
            "Listing price is not entered",
          ]);
        }
      } else {
        // Remove price error if present
        setStepTwoErrorMsg((errors) =>
          errors.filter((element) => element !== "Listing price is not entered")
        );
      }

      // Check payment period
      if (!listingPlateInfo?.paymentPeriod) {
        if (
          !stepTwoErrorMsg.includes("Listing payment period is not entered")
        ) {
          setStepTwoErrorMsg((errors) => [
            ...errors,
            "Listing payment period is not entered",
          ]);
        }
      } else {
        // Remove price error if present
        setStepTwoErrorMsg((errors) =>
          errors.filter(
            (element) => element !== "Listing payment period is not entered"
          )
        );
      }

      // If no error deactivate error block
      if (stepTwoErrorMsg.length === 0) {
        setStepOneError(false);
      }
    }
  }

  function saveStepTwoDraft() {
    // Save data and move to the next stap
    getSession()
      .then((session) => {
        // Deactivate button
        const payload = {
          step: 2,
          listing_id: listingId,
          category: category.value,
          title: listingCarInfo.title,
          description: listingCarInfo.description,
          listing_price: listingCarInfo.listingPrice,
          payment_period: listingCarInfo.paymentPeriod,
          vin:
            listingCarInfo.carNumber.length > 10
              ? listingCarInfo.carNumber
              : undefined,
          plate_number:
            listingCarInfo.carNumber.length <= 10
              ? listingCarInfo.carNumber
              : undefined,
          // Car Detail section block
          make: listingCarDetail.make,
          model: listingCarDetail.model,
          year: listingCarDetail.year,
          body_class: listingCarDetail.bodyClass,
          fuel: listingCarDetail.fuelType,
          door_number: listingCarDetail.doorNumber,
          seat_number: listingCarDetail.seatNumber,
          seat_row_number: listingCarDetail.seatRowNumber,
          images: listingBase64,
        };

        axios({
          method: "post",
          url: ENV.BASE_URL + "/listing/save-draft",
          headers: {
            Authorization: `Bearer ${session.idToken.jwtToken}`,
          },
          data: payload,
        })
          .then((response) => {
            // Check if request is success
            if (response.status === 200) {
              // Change to next step
              navigate("/checkout", {
                state: { listingId: listingId, fromURL: "/create-listing" },
              });
            } else {
              console.log(
                "[ERROR]: Error happens while saving user listing draft in step 2."
              );
            }
            // Show button
            setIsSavingStepTwo(false);
          })
          .catch((err) => {
            console.log(
              "[ERROR]: Error happens while saving user listing draft in step 2."
            );

            setIsSavingStepTwo(false);
          });
      })
      .catch((err) => {
        console.log(err.response.message);
        console.log("[ERROR]: Error happens while fetching user info.");
        setIsSavingStepTwo(false);
      });
  }

  function saveStepTwoPlateDraft() {
    // Save data and move to the next stap
    getSession()
      .then((session) => {
        // Deactivate button
        const payload = {
          step: 2,
          listing_id: listingId,
          category: category.value,
          title: listingPlateInfo.title,
          description: listingPlateInfo.description,
          listing_price: listingPlateInfo.listingPrice,
          payment_period: listingPlateInfo.paymentPeriod,
          vin:
            listingCarInfo.carNumber.length > 10
              ? listingCarInfo.carNumber
              : undefined,
          plate_number:
            listingCarInfo.carNumber.length <= 10
              ? listingCarInfo.carNumber
              : undefined,
          images: listingBase64,
        };

        axios({
          method: "post",
          url: ENV.BASE_URL + "/listing/save-draft",
          headers: {
            Authorization: `Bearer ${session.idToken.jwtToken}`,
          },
          data: payload,
        })
          .then((response) => {
            // Check if request is success
            if (response.status === 200) {
              // Change to next step
              navigate("/checkout", {
                state: { listingId: listingId, fromURL: "/create-listing" },
              });
            } else {
              console.log(
                "[ERROR]: Error happens while saving user listing draft in step 2."
              );
            }
            // Show button
            setIsSavingStepTwo(false);
          })
          .catch((err) => {
            console.log(
              "[ERROR]: Error happens while saving user listing draft in step 2."
            );

            setIsSavingStepTwo(false);
          });
      })
      .catch((err) => {
        console.log(err.response.message);
        console.log("[ERROR]: Error happens while fetching user info.");
        setIsSavingStepTwo(false);
      });
  }

  const onChangeStep = () => {
    // Process step one
    if (step === 1) {
      checkAndSaveStepOneDraft();
      // Set up specific listing block
    } else if (step === 2) {
      if (category.value === "cars") {
        checkAndSaveStepTwoDraft();
      } else if (category.value === "plates") {
        checkAndSaveStepTwoPlateDraft();
      }
    }
  };

  function isEmpty(str) {
    return !str.trim().length;
  }

  function formattedPhone(value) {
    if (!value) return "";
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  function validateEmail(value) {
    return value.match(
      // eslint-disable-next-line
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  const handlePhoneInput = (event) => {
    event.preventDefault();
    setUserPhoneNumber(formattedPhone(event.target.value));
  };

  const handleLocationInput = (event) => {
    event.preventDefault();
    setUserLocation(event.target.value);
    // TODO - v1.1: Get sugestion for zip_code and City and show dropdown
  };

  const handleEmailInput = () => {
    // Check for empty input
    if (isEmpty(userEmail)) {
      setUserEmail(userData.email);
    } else {
      if (validateEmail(userEmail)) {
        setShowEmailInput(!showEmailInput);
        setStepOneErrorMsg((errors) =>
          errors.filter((element) => element !== "Incorrect email")
        );
      } else {
        if (!stepOneErrorMsg.includes("Incorrect email")) {
          setStepOneError(true);
          setStepOneErrorMsg((errors) => [...errors, "Incorrect email"]);
        }
      }
    }
  };

  const onImageInputSelect = (e) => {
    // Check how meny file already added
    const imagesLimit = maxImagesLimit - selectedImages.length;

    if (e.target.files) {
      let fileArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );

      if (fileArray.length >= imagesLimit) {
        fileArray = fileArray.slice(0, imagesLimit);
      }

      let imageArray = Array.from(e.target.files);

      if (imageArray.length >= imagesLimit) {
        imageArray = imageArray.slice(0, imagesLimit);
      }

      // Save preview image
      setSelectedImages((selectedImages) => selectedImages.concat(fileArray));

      // Save file image
      setFileImages((fileImages) => fileImages.concat(imageArray));

      Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));
    }
  };

  // Convert input file to Base64
  async function fileToBase64(file, curentIdx, maxIdx) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      setListingBase64((listingBase64) =>
        listingBase64.concat([reader.result])
      );
      if (curentIdx === maxIdx) {
        setReadyToSendImages(true);
      }
    };
  }

  const renderPhotos = (source) => {
    return source.map((photo) => {
      return (
        <SmallImageCard
          url={photo}
          key={photo}
          onSetMainImage={(url) => {
            onSetMainImage(url);
          }}
          onDeleteImage={(url) => {
            onDeleteImage(url);
          }}
        />
      );
    });
  };

  // Set image as main
  const onSetMainImage = (url) => {
    const nextList = [...selectedImages];
    nextList.sort(function (x, y) {
      return x === url ? -1 : y === url ? 1 : 0;
    });
    setSelectedImages(nextList);
  };

  // Delete image from from prevue
  const onDeleteImage = (url) => {
    // Remove from file
    // Copy of file images array
    let tempFileArray = [...fileImages];

    // get element idx
    let imageIdx = selectedImages.indexOf(url);

    if (imageIdx !== -1) {
      tempFileArray.splice(imageIdx, 1);
      setFileImages(tempFileArray);
    }

    // Remove from preview
    setSelectedImages((image) => image.filter((element) => element !== url));
  };

  const onSuggestedClick = (e) => {
    setIsSelectedLocation(true);

    if (e?.zip_code) {
      setUserLocation(e.city + ", " + e.state + " " + e.zip_code);
      setUserLocationObj(e);
    } else {
      setUserLocation(e.city + ", " + e.state);
      setUserLocationObj(e);
    }

    // Close suggested list
    setSuggestedSearch([]);
  };

  const onLocationDebounceHandler = useMemo(
    () =>
      debounce((location) => {
        setIsSuggestedSearching(true);

        let param = {
          limit: 5,
        };
        let isNum = /^\d+$/.test(location);

        if (isNum) {
          param["zip_code"] = location;
        } else {
          param["city"] = location;
        }

        // Fetch all sugestion
        axios
          .get(ENV.BASE_URL + "/listing/suggested-locations", {
            params: param,
          })
          .then((response) => {
            setSuggestedSearch(response.data.data);
            setIsSuggestedSearching(false);
          })
          .catch((err) => {
            setIsSuggestedSearching(false);

            // Show error
            if (err.response.status === 400) {
              // Location not found error
              setSuggestedSearch([]);
            }
          });
      }, 1000),
    [setSuggestedSearch]
  );

  // Use Effect to handle user input location
  useEffect(() => {
    // // Remove error if present
    // if (suggestedSearchError) {
    //   setSuggestedSearchError(false);
    // }

    if (isSelectedLocation) {
      // Asign selected value if selected
      setIsSelectedLocation(false);
      setSuggestedSearch([]);
    } else {
      // Do suggestion search
      onLocationDebounceHandler(userLocation);
    }

    // eslint-disable-next-line
  }, [userLocation]);

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => {
      onLocationDebounceHandler.cancel();
    };
  }, [onLocationDebounceHandler]);

  return (
    <div className={classes.container}>
      {/* Show loading spinner while fetching data */}
      {isLoading && <Spinner />}

      {/* Show Step #1 */}
      {isLogedIn && step === 1 && (
        <div className={classes.container__step1}>
          <p className={classes.title}>Basic Information</p>

          {/* Category block */}
          <div className={classes.container__step1__categoryBlock}>
            <p className={classes.label}>Select category:</p>
            <Select
              className={classes.container__step1__categoryBlock__select}
              // isMulti="true"
              value={category}
              styles={colourStyles}
              onChange={setCategory}
              isSearchable={false}
              inputProps={{ readOnly: true }}
              options={categoryOptions}
            />
          </div>

          {/* Contact info block */}
          <div className={classes.container__step1__contactInfoBlock}>
            <p className={classes.label}>Contact Information:</p>
            {/* Name section */}
            <div
              className={
                classes.container__step1__contactInfoBlock__nameSection
              }
            >
              {showNameInput ? (
                <input
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder="Jon Dow"
                />
              ) : (
                <p>{userName}</p>
              )}
              {showNameInput ? (
                <button
                  className={classes.buttonSave}
                  onClick={() => {
                    // Check for empty input
                    if (isEmpty(userName)) {
                      setUserName(userData.full_name);
                    } else {
                      setShowNameInput(!showNameInput);
                    }
                  }}
                >
                  Save
                </button>
              ) : (
                <img
                  src={editIcon}
                  alt="Edit"
                  onClick={() => {
                    setShowNameInput(!showNameInput);
                  }}
                />
              )}
            </div>

            {/* Email section */}
            <div
              className={
                classes.container__step1__contactInfoBlock__emailSection
              }
            >
              {showEmailInput ? (
                <input
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  placeholder="jon@dow.com"
                />
              ) : (
                <p>{userEmail}</p>
              )}
              {showEmailInput ? (
                <button
                  className={classes.buttonSave}
                  onClick={handleEmailInput}
                >
                  Save
                </button>
              ) : (
                <img
                  src={editIcon}
                  alt="Edit"
                  onClick={() => {
                    setShowEmailInput(!showEmailInput);
                  }}
                />
              )}
            </div>

            {/* Phone section */}
            <div
              className={
                classes.container__step1__contactInfoBlock__phoneSection
              }
            >
              {showPhoneInput ? (
                <input
                  value={userPhoneNumber}
                  // onChange={(e) => setUserPhoneNumber(e.target.value)}
                  onChange={handlePhoneInput}
                  placeholder="(123) 456-7890"
                />
              ) : (
                <p>
                  {userPhoneNumber ? userPhoneNumber : "Phone not provided"}
                </p>
              )}
              {showPhoneInput ? (
                <button
                  className={classes.buttonSave}
                  onClick={() => {
                    // Check for empty input
                    if (
                      userPhoneNumber.length === 0 ||
                      userPhoneNumber.length === 14
                    ) {
                      setShowPhoneInput(!showPhoneInput);
                    }
                  }}
                >
                  Save
                </button>
              ) : (
                <img
                  src={editIcon}
                  alt="Edit"
                  onClick={() => {
                    setShowPhoneInput(!showPhoneInput);
                  }}
                />
              )}
            </div>
          </div>

          {/* Location section */}
          <div className={classes.container__step1__locationBlock}>
            <p className={classes.label}>Location:</p>
            <div className={classes.searchSection}>
              <input
                value={userLocation}
                onChange={handleLocationInput}
                placeholder="City or zip code"
              />

              {/* Progres status block */}
              {isSuggestedSearching && (
                <div className={classes.searchSection__suggestionsBlock}>
                  <p>Searching...</p>
                </div>
              )}

              {/* Result search block */}
              {!isSuggestedSearching && suggestedSearch.length >= 1 && (
                <div className={classes.searchSection__suggestionsBlock}>
                  {suggestedSearch.map((suggest, i) => (
                    <p key={i} onClick={() => onSuggestedClick(suggest)}>
                      {suggest.zip_code
                        ? suggest.zip_code + ", " + suggest.city
                        : suggest.city}
                      , {suggest.state}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className={classes.container__step1__saveAndNextBlock}>
            {!isSavingStepOne && (
              <button
                className={classes.buttonSave}
                onClick={() => {
                  onChangeStep();
                }}
              >
                Save and Next
              </button>
            )}
            {isSavingStepOne && <p>Loading ... </p>}
          </div>

          <div
            className={classes.errorBlock}
            style={{ display: stepOneError ? "block" : "none" }}
          >
            <ul>
              {stepOneErrorMsg.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      )}

      {isLogedIn && step === 2 && (
        <div className={classes.container__step2}>
          <div className={classes.title}>
            <p>Listing Information</p>
          </div>

          <div className={classes.container__step2__firstRowSection}>
            <div
              className={classes.container__step2__firstRowSection__leftSection}
            >
              <div
                className={
                  classes.container__step2__firstRowSection__leftSection__imgSection
                }
              >
                {/* Car listing images */}
                {category.value === "cars" && (
                  <div
                    className={
                      classes.container__step2__firstRowSection__leftSection__imgSection__mainImgBlock
                    }
                  >
                    {selectedImages.length === 0 ? (
                      <img src={carDefaultImage} alt="Images" />
                    ) : (
                      <img src={selectedImages[0]} alt="Images" />
                    )}
                  </div>
                )}

                {/* Plates listing images */}
                {category.value === "plates" && (
                  <div
                    className={
                      classes.container__step2__firstRowSection__leftSection__imgSection__mainImgBlock
                    }
                  >
                    {selectedImages.length === 0 ? (
                      <img src={platesDefaultImage} alt="Images" />
                    ) : (
                      <img src={selectedImages[0]} alt="Images" />
                    )}
                  </div>
                )}

                <div
                  className={
                    classes.container__step2__firstRowSection__leftSection__imgSection__smallIconsBlock
                  }
                >
                  {selectedImages && renderPhotos(selectedImages)}

                  {selectedImages.length < maxImagesLimit && (
                    <div>
                      <label htmlFor="file-input">
                        <img src={logoAdd} alt="Add" />
                      </label>

                      <input
                        id="file-input"
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={onImageInputSelect}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div
              className={
                classes.container__step2__firstRowSection__rightSection
              }
            >
              {category.value === "cars" && (
                <CarListingInfo
                  listingCarInfo={listingCarInfo}
                  setListingCarInfo={setListingCarInfo}
                />
              )}

              {category.value === "plates" && (
                <PlateListingInfo
                  listingPlateInfo={listingPlateInfo}
                  setListingPlateInfo={setListingPlateInfo}
                />
              )}
            </div>
          </div>

          {category.value === "cars" && (
            <CarDetailBlock
              listingCarDetail={listingCarDetail}
              setListingCarDetail={setListingCarDetail}
            />
          )}

          {!isSavingStepTwo && (
            <div className={classes.container__step2__saveAndNextBlock}>
              <button
                className={classes.buttonSave}
                onClick={() => {
                  setStep(1);
                }}
              >
                Save and Back
              </button>

              <button
                className={classes.buttonSave}
                onClick={() => {
                  onChangeStep();
                }}
              >
                Save and Checkout
              </button>
            </div>
          )}
          {isSavingStepTwo && <p>Loading ... </p>}
          {/* Error block */}
          <div
            className={classes.errorBlock}
            style={{ display: stepTwoError ? "block" : "none" }}
          >
            <ul>
              {stepTwoErrorMsg.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateListing;
