import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import axios from "axios";

import classes from "./CarDetailBlock.module.scss";

import ENV from "../../env";

const CarDetailBlock = ({ listingCarDetail, setListingCarDetail }) => {
  const [makeOptions, setMakeOptions] = useState([]);
  const [selectedMake, setSelectedMake] = useState({});

  const [modelOptions, setModelOptions] = useState([]);
  const [selectedModel, setSelectedModel] = useState({});

  const isInit = useRef(true);

  useEffect(() => {
    if (isInit.current) {
      isInit.current = false;

      // Load all makes
      axios.get(ENV.BASE_URL + "/cars/makes").then((makeResponse) => {
        setMakeOptions(
          makeResponse.data.map((obj) => {
            return renameKeys({ make_id: "value", make_name: "label" }, obj);
          })
        );
      });
    }
  }, []);

  // Handle onChange Car Make
  useEffect(() => {
    if (Object.keys(selectedMake).length !== 0) {
      // Set user car diteail with selected make
      setListingCarDetail({
        ...listingCarDetail,
        make: selectedMake.label,
        model: "",
      });

      // get Models for selected make
      axios
        .get(
          ENV.BASE_URL + "/cars/models-by-make-id?make-id=" + selectedMake.value
        )
        .then((responseModel) => {
          setModelOptions(
            responseModel.data.map((obj) => {
              return renameKeys(
                { model_id: "value", model_name: "label" },
                obj
              );
            })
          );
        });
    }
    // eslint-disable-next-line
  }, [selectedMake]);

  // Handle onChange Car Model
  useEffect(() => {
    if (Object.keys(selectedModel).length !== 0) {
      // Set user car diteail with selected model
      setListingCarDetail({
        ...listingCarDetail,
        model: selectedModel.label,
      });
    }
    // eslint-disable-next-line
  }, [selectedModel]);

  let renameKeys = (keysMap, object) =>
    Object.keys(object).reduce(
      (acc, key) => ({
        ...acc,
        ...{ [keysMap[key] || key]: object[key] },
      }),
      {}
    );

  const handleChange = (e) => {
    setListingCarDetail({
      ...listingCarDetail,
      [e.target.name]: e.target.value,
    });
  };
  const colourStyles = {
    control: (styles, { isFocused, isSelected }) => ({
      ...styles,
      fontSize: 16,
      borderColor: "#1d1d1d",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#1d1d1d",
      },
    }),
    menuList: (styles) => ({
      ...styles,
    }),

    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused ? "#f6f6f6" : isSelected ? "#f6f6f6" : undefined,
      color: "#1d1d1d",
      zIndex: 1,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 1,
    }),
    singleValue: (provided) => ({ ...provided, color: "#1d1d1d" }),

    dropdownIndicator: (base) => ({
      ...base,
      color: "#1d1d1d",
    }),
  };
  return (
    <div className={classes.car_detail_container}>
      <div className={classes.title}>
        <p>Car details</p>
      </div>
      <div className={classes.row}>
        <div className={classes.input_block}>
          <p className={classes.label}>Make:</p>
          <Select
            options={makeOptions}
            styles={colourStyles}
            onChange={setSelectedMake}
            placeholder={"Toyota, BMW, etc."}
          />
        </div>
        <div className={classes.input_block}>
          <p className={classes.label}>Model:</p>
          <Select
            options={modelOptions}
            styles={colourStyles}
            onChange={setSelectedModel}
            placeholder={"Corolla, X7 etc."}
          />
        </div>
      </div>

      <div className={classes.row}>
        <div className={classes.input_block}>
          <p className={classes.label}>Year:</p>
          <input
            type="number"
            name="year"
            value={listingCarDetail.year}
            onChange={handleChange}
            className={classes.car_container}
            placeholder="2019"
          />
        </div>
        <div className={classes.input_block}>
          <p className={classes.label}>Body Class:</p>
          <input
            type="text"
            name="bodyClass"
            value={listingCarDetail.bodyClass}
            onChange={handleChange}
            className={classes.car_container}
            placeholder="Sedan"
          />
        </div>
        <div className={classes.input_block}>
          <p className={classes.label}>Fuel Type:</p>
          <input
            type="text"
            name="fuelType"
            value={listingCarDetail.fuelType}
            onChange={handleChange}
            className={classes.car_container}
            placeholder="Gasolin"
          />
        </div>
      </div>

      <div className={classes.row}>
        <div className={classes.input_block}>
          <p className={classes.label}>Number of Doors:</p>
          <input
            type="number"
            name="doorNumber"
            value={listingCarDetail.doorNumber}
            onChange={handleChange}
            className={classes.car_container}
            placeholder="4"
          />
        </div>
        <div className={classes.input_block}>
          <p className={classes.label}>Number of Seats:</p>
          <input
            type="number"
            name="seatNumber"
            value={listingCarDetail.seatNumber}
            onChange={handleChange}
            className={classes.car_container}
            placeholder="5"
          />
        </div>
        <div className={classes.input_block}>
          <p className={classes.label}>Number of Seat Rows:</p>
          <input
            type="number"
            name="seatRowNumber"
            value={listingCarDetail.seatRowNumber}
            onChange={handleChange}
            className={classes.car_container}
            placeholder="2"
          />
        </div>
      </div>
    </div>
  );
};

export default CarDetailBlock;
