import React from 'react';
import { useNavigate } from 'react-router-dom';

import classes from './PopupReminder.module.scss';

const PopupReminder = (props) => {
  const navigate = useNavigate();
  return (
    <div className={classes.popupBox}>
      <div className={classes.popupBox__box}>
      {/* <p className={classes.popupBox__box__close} onClick={props.handleClose}>X</p> */}
      
      <div className={classes.popupBox__box__content}>
        {props.image && <div className={classes.popupBox__box__content__left}>
            <img src={props.image} alt="Images"/>
        </div>}
        
        <div className={classes.popupBox__box__content__right}>
            <h2>{props.title}</h2>
            
            <p>{props.content}</p>
            
            <button onClick={() => {navigate(props.navigateTo)}}>{props.navigateToBtnText}</button>
        </div>
      </div>
      
      </div>
    </div>
  )
}

export default PopupReminder