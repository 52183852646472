import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartProductCount: 0,
    cartTotalPrice: 0,
    shippingPrice: 4.99,
  },
  reducers: {
    // Increment cart counter
    incrementCartProductCounter: (state) => {
      state.cartProductCount += 1;
    },

    // Decrement cart counter
    decrementCartProductCounter: (state) => {
      state.cartProductCount -= 1;
    },

    // Set cart counter after fetching from API
    setCartProductCounter: (state, action) => {
      state.cartProductCount = action.payload;
    },

    // Add amount Cart total
    addToCartTotalPrice: (state, action) => {
      state.cartTotalPrice += action.payload;
    },

    // Delete amount from cart total
    minusFromCartTotalPrice: (state, action) => {
      state.cartTotalPrice -= action.payload;
    },
    // Reset cart amount total
    resetCartTotalPrice: (state) => {
      state.cartTotalPrice = 0;
    },
  },
});

export const {
  incrementCartProductCounter,
  decrementCartProductCounter,
  setCartProductCounter,
  addToCartTotalPrice,
  minusFromCartTotalPrice,
  resetCartTotalPrice,
} = cartSlice.actions;

export default cartSlice.reducer;
