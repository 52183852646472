import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import classes from "./ProductItem.module.scss";

import ImageSlider from "../ImageSlider";
import { AccountContext } from "./../auth/account";
import ENV from "../../env";
import { incrementCartProductCounter } from "../../redux/slices/cartSlice";
import ButtonAmazon from "../ButtonAmazon";

const ProductItem = ({ product }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { getSession } = useContext(AccountContext);
  const [isLogedIn, setIsLogedIn] = useState(false);
  const [userJwt, setUserJWT] = useState("");
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [isBuyingNow, setIsBuyingNow] = useState(false);

  // Use effect for user session
  useEffect(() => {
    getSession()
      .then((session) => {
        setIsLogedIn(true);
        setUserJWT(session.idToken.jwtToken);
      })
      .catch((err) => {
        setIsLogedIn(false);
      });
  }, [getSession, isLogedIn]);

  const onAddToCartHandler = () => {
    if (isLogedIn) {
      // Activate loader
      setIsAddingToCart(true);

      axios
        .post(
          ENV.BASE_URL + "/users/cart",
          {
            "product-id": product.product_id,
            quantity: 1,
            price: product.price,
          },
          {
            headers: { Authorization: `Bearer ${userJwt}` },
          }
        )
        .then(() => {
          dispatch(incrementCartProductCounter());
          setIsAddingToCart(false);
        })
        .catch((err) => {
          console.log(err);
          setIsAddingToCart(false);
        });
    }
  };

  const onBuyNowHandler = () => {
    if (isLogedIn) {
      // Activate loader
      setIsBuyingNow(true);

      axios
        .post(
          ENV.BASE_URL + "/users/cart",
          {
            "product-id": product.product_id,
            quantity: 1,
            price: product.price,
          },
          {
            headers: { Authorization: `Bearer ${userJwt}` },
          }
        )
        .then(() => {
          dispatch(incrementCartProductCounter());
          setIsBuyingNow(false);
          navigate("/cart");
        })
        .catch((err) => {
          console.log(err);
          setIsBuyingNow(false);
        });
    } else {
      navigate("/login");
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.main}>
        <div className={classes.left}>
          <ImageSlider imageList={product.images} />
        </div>

        <div className={classes.right}>
          <div className={classes.right__main}>
            <h2 className={classes.right__main__title}>{product.title}</h2>
            <p className={classes.right__main__description}>
              {product.description}
            </p>
            {product.details.length > 0 && (
              <div className={classes.right__main__detailsList}>
                {product.details.map((detail, i) => (
                  <p key={i}>
                    <strong>{detail.key}:</strong> {detail.data}
                  </p>
                ))}
              </div>
            )}
          </div>

          <div className={classes.right__bottom}>
            <div className={classes.right__bottom__price}>
              <h2 className={classes.__green}>${product.price}</h2>
              {/* FIXME Change url to dynamic one */}
              <ButtonAmazon redirectURL="https://a.co/d/6E3TYZq" />
            </div>
            <div className={classes.right__bottom__buttons}>
              {!isBuyingNow ? (
                <button className={classes.button} onClick={onBuyNowHandler}>
                  Buy now
                </button>
              ) : (
                <p>Loading...</p>
              )}
              {isLogedIn &&
                (!isAddingToCart ? (
                  <button
                    className={classes.button}
                    onClick={onAddToCartHandler}
                  >
                    Add to cart
                  </button>
                ) : (
                  <p>Loading...</p>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
