import React from "react";

import classes from "./CarListingInfo.module.scss";

const CarListingInfo = ({ listingCarInfo, setListingCarInfo }) => {
  const handleChange = (e) => {
    setListingCarInfo({ ...listingCarInfo, [e.target.name]: e.target.value });
  };

  return (
    <div className={classes.car_container}>
      <div className={classes.title_block}>
        <p className={classes.label}>Listing title:</p>
        <input
          type="text"
          name="title"
          value={listingCarInfo.title}
          className={classes.car_container}
          placeholder="TLC car for rent by owner"
          onChange={(e) => handleChange(e)}
        />
      </div>

      <div className={classes.title_block}>
        <p className={classes.label}>Short description (Optional):</p>
        <textarea
          rows="3"
          name="description"
          value={listingCarInfo.description}
          onChange={handleChange}
          className={classes.car_container}
          placeholder="Hello, I have a car with TLC plates. Anyone interested in having it please contact me."
        />
      </div>

      <div className={classes.price_block}>
        <p className={classes.label}>Asking price:</p>

        <div className={classes.price_block__inputs}>
          <input
            type="number"
            name="listingPrice"
            value={listingCarInfo.listingPrice}
            className={classes.car_container}
            placeholder="$ 450"
            onChange={handleChange}
          />
          <div className={classes.price_block__inputs__radio}>
            <div className={classes.price_block__inputs__radio__radio_block}>
              <input
                className={classes.car_container}
                type="radio"
                value="weekly"
                name="paymentPeriod"
                checked={listingCarInfo.paymentPeriod === 'weekly'}
                onChange={handleChange}
              />
              <p>Weekly</p>
            </div>
            <div className={classes.price_block__inputs__radio__radio_block}>
              <input
                className={classes.car_container}
                type="radio"
                value="monthly"
                name="paymentPeriod"
                checked={listingCarInfo.paymentPeriod === 'monthly'}
                onChange={handleChange}
              />
              <p>Monthly</p>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.title_block}>
        <p className={classes.label}>Vehicle identification:</p>
        <input
          type="text"
          name="carNumber"
          value={listingCarInfo.carNumber}
          className={classes.car_container}
          // placeholder="VIN, DMV plate or Vehicle license"
          placeholder="VIN or DMV plate number"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default CarListingInfo;
