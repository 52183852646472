import {React, useRef, useEffect, useState, useContext} from "react";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";

import classes from "./Listing.module.scss";

import carDefaultImg from "../../images/listing_default_car.svg";
import plateDefaultImg from "../../images/listing_default_plates.png";

import ENV from "../../env";
import { AccountContext } from "../auth/account";

const Listing = ({ listingData, userId, openImage }) => {
  const { getSession } = useContext(AccountContext);
  const isInit = useRef(true);
  const [listingStatus, setListingStatus] = useState({});

  const statusOptions = [
    { value: "ACTIVE", label: "Active" },
    { value: "INACTIVE", label: "Deactivate" }
  ];

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      fontSize: 12,
      border: 1,
      boxShadow: "none",
    }),
    menuList: (styles) => ({
      ...styles,
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused ? "#f6f6f6" : isSelected ? "#f6f6f6" : undefined,
      color: "#1d1d1d",
      zIndex: 1,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 1,
    }),
    singleValue: (provided) => ({ ...provided, color: "#1d1d1d" }),

    dropdownIndicator: (base) => ({
      ...base,
      color: "#1d1d1d",
    }),
  };

  useEffect(() => {
    if (isInit.current) {
      isInit.current = false;
      
      if (listingData.status === "ACTIVE") {
        setListingStatus({ value: listingData.status, label: "Active" });
      }
      else if (listingData.status === "INACTIVE") {
        setListingStatus({ value: listingData.status, label: "Deactivate" });
      }
    }
    // eslint-disable-next-line
  }, []);

  const onChangeStatus = (e) => {
    // Make API call to update listing status
    toast.info("Updating listing status...", {
        position: "top-center",
        autoClose: 1000,
        theme: "colored"
      }
    );
    // Get user session
    getSession()
      .then((session) => {
        axios.post(ENV.BASE_URL  + "/listing/change-status", {
          listing_id: listingData.listing_id,
          listing_status: e.value
        }, {
          headers: {
            Authorization: `Bearer ${session.idToken.jwtToken}`,
          },
        }).then((response) => {
          if (response.status === 200) {
            setListingStatus(e);
            toast.success("Listing status updated successfully", {
              position: "top-center",
              autoClose: 3000,
              theme: "colored",
            });
          }
        }).catch(() => {
          toast.error("Cannot update the listing status at this time. Please try later", {
            position: "top-center",
            autoClose: 3000,
            theme: "colored",
          });
      });
    });
  }

  return (
    <div className={classes.container}>
      <div className={classes.listingBlock}>
        <p className={classes.title}>{listingData.title}</p>

        <div className={classes.imageBlock}>
          {/* Default images */}
          {listingData.images.length === 0 && (
            <div className={classes.imageBlock__mainImage}>
              {/* Plate */}
              {listingData.category === "plates" && (
                <img src={plateDefaultImg} alt="Default plate" onClick={openImage} />
              )}

              {/* Cars */}
              {listingData.category === "cars" && (
                <img src={carDefaultImg} alt="Default car" onClick={openImage} />
              )}
            </div>
          )}

          {/* Main Image */}
          {listingData.images.length !== 0 && (
            <div className={classes.imageBlock__mainImage}>
              <img
                src={
                  "https://tlc-listing-images.s3.amazonaws.com/" +
                  listingData.listing_id +
                  "/" +
                  listingData.images[0]
                }
                alt="Default"
                onClick={openImage}
              />
            </div>
          )}

          {/* Sub images */}
          {listingData.images.length !== 0 && (
            <div className={classes.imageBlock__subImages}>
              {listingData.images.map((image) => {
                return (
                  <img
                    src={
                      "https://tlc-listing-images.s3.amazonaws.com/" +
                      listingData.listing_id +
                      "/" +
                      image
                    }
                    alt="Default car"
                    key={image}
                  />
                );
              })}
            </div>
          )}
        </div>

        {/* Price */}
        <p className={classes.priceBlock}>
          <span className={classes.priceBlock__price}>{listingData.price}</span>{" "}
          {listingData.payment_period}
        </p>

        {/* Description */}
        {listingData?.description && <p className={classes.title}>Description</p>}
        {listingData?.description && <pre><p>{listingData.description}</p></pre>}
        
        {/* Plate and vin # */}
        {listingData?.plate_number && (
          <p>Vehicle plate #: {listingData.plate_number}</p>
        )}
        {listingData?.vin && <p>Vehicle vin #: {listingData.vin}</p>}

        <hr />

        {/* Car details */}
        {listingData.category === "cars" && (
          <div>
            <p className={classes.title}>Car details</p>

            <div className={classes.detailBlock}>
              <p><strong>Make:</strong> {listingData.car_make}</p>
              <p><strong>Model:</strong> {listingData.car_model}</p>
              <p><strong>Year:</strong> {listingData.car_year}</p>

              {listingData?.car_body_class && (
                <p><strong>Body Class:</strong> {listingData.car_body_class}</p>
              )}
              {listingData?.car_body_class && (
                <p><strong>Fuel Type:</strong> {listingData.car_body_class}</p>
              )}
              {listingData?.car_door_number && (
                <p><strong>Number of Doors:</strong> {listingData.car_door_number}</p>
              )}
              {listingData?.car_seat_number && (
                <p><strong>Number of Seats:</strong> {listingData.car_seat_number}</p>
              )}
              {listingData?.car_body_class && (
                <p><strong>Number of Seat Rows:</strong> {listingData.car_seat_row_number}</p>
              )}
            </div>
          </div>
        )}
      </div>

      <div className={classes.contactBlock}>
        <p className={classes.subTitle}>Contact info</p>
        <div className={classes.contactDetails}>
          <p>
            <strong>Name: </strong> {listingData.name}
          </p>
          <p>
            <strong>Email: </strong>
            {listingData.email}
          </p>
          {listingData?.phone_number && (
            <p>
              <strong>Phone: </strong>
              {listingData.phone_number}
            </p>
          )}
          <p>
            <strong>Location: </strong>
            {listingData.location?.zip_code 
            ? listingData.location.city + ", " + listingData.location.state + " " + listingData.location.zip_code
            : listingData.location.city + ", " + listingData.location.state}
          </p>
        </div>

        <hr />
        {listingData.user_id === userId && (
          <div className={classes.editBlock}>
            <Select
              className={classes.container__step1__categoryBlock__select}
              value={listingStatus}
              styles={colourStyles}
              onChange={onChangeStatus}
              isSearchable={false}
              inputProps={{ readOnly: true }}
              options={statusOptions}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Listing;
