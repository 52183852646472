import React, { useEffect, useState, useContext } from "react";
import classes from "./CheckoutPage.module.scss";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import ENV from "../../env";

import { AccountContext } from "../../components/auth/account";
import ListingPreview from "../../components/listing/ListingPreview";
import ListingCheckout from "../../components/listing/ListingCheckout";
import Spinner from "../../components/Spinner";

const CheckoutPage = () => {

  const location = useLocation();
  const { getSession } = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState([]);
  const [urlState, setUrlState] = useState({});
  const [selectedPM, setSelectedPM] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [listingSubmited, setListingSubmited] = useState(false);

  useEffect(() => {
    setUrlState(location.state);
    setIsLoading(false);
  }, [location]);

  useEffect(() => {
    if (errorMsg.length) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [errorMsg]);

  const onPayAndPost = () => {
    // Check if pm selected
    if (!selectedPM) {
      if (!errorMsg.includes("Payment method not selected")) {
        setErrorMsg((errorMsg) => [...errorMsg, "Payment method not selected"]);
      }
    } else {
      setErrorMsg((errorMsg) =>
        errorMsg.filter((element) => element !== "Payment method not selected")
      );

      // Make API call and to payment
      getSession()
        .then((session) => {
          setIsSubmit(true);
          axios
            .post(
              ENV.BASE_URL + "/payments/create-payment-intent",
              {
                "user-pm": selectedPM,
                "payment-amount": 108,
                "listing-id": urlState.listingId,
              },
              {
                headers: {
                  Authorization: `Bearer ${session.idToken.jwtToken}`,
                },
              }
            )
            .then((response) => {
              // Check if request is success
              if (response.status === 200) {
                setIsSubmit(false);
                setListingSubmited(true);
                console.log("Sending listing confirmation email ...");
                const today = new Date(Date.now());

                if (session?.email) {
                  axios.post(ENV.BASE_EMAIL_URL + "/emails/send-email", {
                    template_name: "listing_payment_confirmation.html",
                    data: {
                      date: today.toLocaleDateString(),
                      items: "Listing Creation",
                      subtotal: "0.99",
                      tax: "0.09",
                      order_total: "1.08",
                    },
                    subject: "Order confirmation",
                    recipients: [session.email],
                  });
                }

                // Send email notification about new listing
                console.log("Sending listing under review");

                axios.post(ENV.BASE_EMAIL_URL + "/emails/send-email", {
                  template_name: "new_listing_under_review.html",
                  data: {
                    date: today.toLocaleDateString(),
                    listing_id: urlState.listingId,
                  },
                  subject: "New Listing For Review - " + urlState.listingId,
                  recipients: ['info.drivetlc@gmail.com'],
                });

              } else {
                console.log("[ERROR]: Error happens while fetching user info.");
                setIsSubmit(false);
              }
            });
        })
        .catch((err) => {
          console.log(err);
          setIsSubmit(false);
        });
    }
  };

  return (
    <>
      {listingSubmited && (
        <div className={classes.container}>
          <p className={classes.title}>Thank you for your order</p>
          <p className={classes.success}>
            We will process and publish your listing shortly
          </p>
          <div className={classes.submitButtonBlock}>
            <Link to={`/`} className={classes.button}>
              Go to main page
            </Link>
          </div>
        </div>
      )}

      {!listingSubmited && (
        <div className={classes.container}>
          <p className={classes.title}>Review & Checkout</p>

          {isLoading && <Spinner />}

          {!isLoading && (
            <div className={classes.mainSection}>
              <div className={classes.preview}>
                <ListingPreview listingId={urlState.listingId} />
              </div>

              <div className={classes.checkout}>
                <ListingCheckout
                  state={urlState}
                  isError={(e) => setIsError(e)}
                  errorMsg={errorMsg}
                  setErrorMsg={(e) => setErrorMsg(e)}
                  setSelectedPM={(pm) => setSelectedPM(pm)}
                />
              </div>
            </div>
          )}

          {/* Error block */}
          <div
            className={classes.errorBlock}
            style={{ display: isError ? "block" : "none" }}
          >
            <ul>
              {errorMsg.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>

          {/* Submit */}
          {!isSubmit && (
            <div className={classes.submitButtonBlock}>
              <button className={classes.button} onClick={onPayAndPost}>
                Pay and Post
              </button>
            </div>
          )}

          {isSubmit && (
            <p
              style={{
                justifyContent: "center",
                display: "flex",
                margin: "1rem",
              }}
            >
              Loading...
            </p>
          )}
        </div>
      )}
    </>
  );
};

export default CheckoutPage;
