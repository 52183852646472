import React, {useState} from "react";

import classes from "./ReportIssuePopup.module.scss";
import { toast } from "react-toastify";

const ReportIssuePopup = (props) => {
  const [checkboxesList, setCheckboxesList] = useState([]);
  const [otherIssue, setOtherIssue] = useState('');

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    // Add to checkboxesList and remove if already exists
    if (checkboxesList.includes(event.target.value)) {
      setCheckboxesList(checkboxesList.filter(item => item !== event.target.value));
    }
    else {
      setCheckboxesList([...checkboxesList, event.target.value]);
    }
  };

  // Handle other issue change
  const handleOtherIssueChange = (event) => {
    setOtherIssue(event.target.value);
  };

  // Handle submit
  const handleSubmit = (checkboxesList, otherIssue) => {
    // validate inputs
    if (checkboxesList.length === 0 && otherIssue === '') {
      toast.error('Please select at least one issue or enter other issue', {
        position: "top-center",
        autoClose: 2000,
        theme: "colored"
      });
      return;
    }

    props.handleSubmited(checkboxesList, otherIssue);

    // Close popup
    props.handleClose();
  };

  return (
    <div className={classes.popupBox}>
      <div className={classes.popupBox__box}>
        <div className={classes.closeBtn} onClick={props.handleClose}><span>x</span></div> 
        <h2>{props.title}</h2>
        <p>{props.content}</p>
        <div className={classes.popupBox__row}>
          <input 
            type='checkbox' 
            id='checkbox_1'
            value='Spelling or grammar'
            onChange={handleCheckboxChange}
          />
          <label htmlFor='checkbox_1'>Spelling or grammar</label>
        </div>

        <div className={classes.popupBox__row}>
          <input 
            type='checkbox' 
            id='checkbox_2'
            value='Outdated Information'
            onChange={handleCheckboxChange}
          />
          <label htmlFor='checkbox_2'>Outdated Information</label>
        </div>

        <div className={classes.popupBox__row}>
          <input 
            type='checkbox' 
            id='checkbox_3'
            value='Confusing Answer Choices'
            onChange={handleCheckboxChange}
          />
          <label htmlFor='checkbox_3'>Confusing Answer Choices</label>
        </div>

        <div className={classes.popupBox__row}>
          <input 
            type='checkbox' 
            id='checkbox_4'
            value='Incorrect Correct Answers'
            onChange={handleCheckboxChange}
          />
          <label htmlFor='checkbox_4'>Incorrect Correct Answers</label>
        </div>

        <div className={classes.popupBox__row}>
          <input 
            type='text'
            placeholder="Any other issue (optional)"
            value={otherIssue}
            onChange={handleOtherIssueChange}
          />
        </div>

        <button
          className={classes.popupBox__btnClose}
          onClick={() => {handleSubmit(checkboxesList, otherIssue)}}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ReportIssuePopup;
