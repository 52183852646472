import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import ENV from "../../env";

import classes from "./ListingPreview.module.scss";
import { AccountContext } from "../../components/auth/account";
import Spinner from "../../components/Spinner";

const ListingPreview = ({ listingId }) => {
  const listingUrl = ENV.BASE_URL + "/listing/" + listingId;

  const { getSession } = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(true);
  const [listingData, setListingData] = useState({});

  useEffect(() => {
    // Fetch listing by ID
    getSession()
      .then((session) => {
        axios
          .get(listingUrl, {
            headers: { Authorization: `Bearer ${session.idToken.jwtToken}` },
          })
          .then((response) => {
            // Check if request is success
            if (response.status === 200) {
              setListingData(response.data.data);
              console.log(response.data.data);
              setIsLoading(false);
            } else {
              console.log("[ERROR]: Error happens while fetching user info.");
            }
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [getSession, listingUrl]);

  return (
    <>
      {isLoading && <Spinner />}

      {!isLoading && (
        <div className={classes.container}>
          <p className={classes.title}>Basic Information</p>

          <div className={classes.basicInfo}>
            <p>Name: {listingData.name}</p>
            <p>Email: {listingData.email}</p>
            {listingData?.phone_number && (
              <p>Phone: {listingData.phone_number}</p>
            )}
            <p>Category: {listingData.category}</p>
            <p>Location: {listingData.location?.zip_code 
            ? listingData.location.city + ", " + listingData.location.state + " " + listingData.location.zip_code
            : listingData.location.city + ", " + listingData.location.state}</p>
          </div>

          {listingData.images.length !== 0 && (
            <p className={classes.title}>Images</p>
          )}

          {listingData.images.length !== 0 && (
            <div className={classes.imagesSection}>
              {listingData.images.map((image) => {
                return (
                  <img
                    src={
                      "https://tlc-listing-images.s3.amazonaws.com/" +
                      listingData.listing_id +
                      "/" +
                      image
                    }
                    alt="listing img"
                    key={image}
                  />
                );
              })}
            </div>
          )}

          <p className={classes.title}>Listing Information</p>

          <div className={classes.listingInfo}>
            <p>Title: {listingData.title}</p>
            {listingData?.description && (
              <p>Description: {listingData.description}</p>
            )}
            <p>
              Asking price: {listingData.price} / {listingData.payment_period}{" "}
            </p>
            {listingData?.plate_number && (
              <p>Vehicle plate #: {listingData.plate_number}</p>
            )}
            {listingData?.vin && <p>Vehicle vin #: {listingData.vin}</p>}
          </div>

          {listingData.category === "cars" && (
            <div>
              <p className={classes.title}>Car details</p>

              <div className={classes.listingInfo}>
                <p>Make: {listingData.car_make}</p>
                <p>Model: {listingData.car_model}</p>
                <p>Year: {listingData.car_year}</p>

                {listingData?.car_body_class && (
                  <p>Body Class: {listingData.car_body_class}</p>
                )}
                {listingData?.car_body_class && (
                  <p>Fuel Type: {listingData.car_body_class}</p>
                )}
                {listingData?.car_door_number && (
                  <p>Number of Doors: {listingData.car_door_number}</p>
                )}
                {listingData?.car_seat_number && (
                  <p>Number of Seats: {listingData.car_seat_number}</p>
                )}
                {listingData?.car_body_class && (
                  <p>Number of Seat Rows: {listingData.car_seat_row_number}</p>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ListingPreview;
