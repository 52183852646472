import React from "react";
import Marketplace from "../components/marketplace/Marketplace";

const MarketplacePage = () => {
  return (
    <div>
      <Marketplace />
    </div>
  );
};

export default MarketplacePage;
