import React, { useEffect, useState } from "react";

import classes from "./ShippingDetails.module.scss";

const ShippingDetails = ({ setShippingDetails }) => {
  const [shippingAddress, setShippingAddress] = useState({
    full_name: "",
    address_one: "",
    address_two: "",
    address_city: "",
    address_state: "",
    address_zip: "",
  });

  useEffect(() => {
    setShippingDetails(shippingAddress);
    // eslint-disable-next-line
  }, [shippingAddress]);

  const handleInputChange = (e) => {
    let updatedValue = {};
    updatedValue = { [e.target.name]: e.target.value };
    setShippingAddress((shippingAddress) => ({
      ...shippingAddress,
      ...updatedValue,
    }));
  };
  return (
    <div className={classes.container}>
      <h2 className={classes.title}>Shipping Details</h2>

      <div className={classes.fullRow}>
        <input
          type="text"
          name="full_name"
          placeholder="Full name (First and Last name)"
          onChange={handleInputChange}
        />
      </div>

      <div className={classes.fullRow}>
        <input
          type="text"
          name="address_one"
          placeholder="Street address or P.O. Box"
          onChange={handleInputChange}
        />
      </div>

      <div className={classes.fullRow}>
        <input
          type="text"
          name="address_two"
          placeholder="Apt, suite, unit, building, flor, etc."
          onChange={handleInputChange}
        />
      </div>

      <div className={classes.sharedRow}>
        <input
          type="text"
          name="address_city"
          placeholder="City"
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="address_state"
          placeholder="State"
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="address_zip"
          placeholder="ZIP Code"
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export default ShippingDetails;
