import React, { useState } from "react";
import { TfiAngleLeft } from "react-icons/tfi";
import { TfiAngleRight } from "react-icons/tfi";

import classes from "./ImageSlider.module.scss";

const ImageSlider = ({ imageList }) => {
    const [selectedImage, setSelectedImage] = useState(0);

    const onLeftClickHandler = () => {
        setSelectedImage(selectedImage - 1);
    }
    
    const onRightClickHandler = () => {
        setSelectedImage(selectedImage + 1);
    }
  return (
    <div className={classes.container}>
      <img src={imageList[selectedImage]} alt="product" />
      
      {(imageList.length > 1) &&
      (
        <div className={classes.nav}>
          <div className={`${selectedImage !== 0 ? classes.nav__btn : classes.nav__btn__hide }`} onClick={onLeftClickHandler}>
            <TfiAngleLeft />
          </div>
          <div className={`${selectedImage !== imageList.length - 1 ? classes.nav__btn : classes.nav__btn__hide }`} onClick={onRightClickHandler}>
            <TfiAngleRight />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageSlider;
