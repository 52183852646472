import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import ListingFilter from "./ListingFilter";
import ListingList from "./ListingList";
import ENV from "./../../env";

import classes from "./Marketplace.module.scss";

const Marketplace = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [listings, setListings] = useState([]);
  const [searchNewListings, setSearchNewListings] = useState(false);
  const [toResetPage, setToResetPage] = useState(false);
  const isInit = useRef(true);

  // Redux
  const savedFilters = useSelector((state) => state.listingFilters.filters);

  useEffect(() => {
    if (isInit.current) {
      isInit.current = false;
      setIsLoading(true);

      axios
        .get(ENV.BASE_URL + "/listing-all", { params: savedFilters })
        .then((response) => {
          setListings(response.data.data.listings);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });

      // Reset paginator to first page
      setToResetPage(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchNewListings) {
      setSearchNewListings(false);
      setIsLoading(true);

      axios
        .get(ENV.BASE_URL + "/listing-all", { params: savedFilters })
        .then((response) => {
          setListings(response.data.data.listings);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });

      // Reset paginator to first page
      setToResetPage(true);
    }
    // eslint-disable-next-line
  }, [searchNewListings]);

  return (
    <div className={classes.container}>
      <div className={classes.mainSection}>
        <ListingFilter searchNewListings={() => setSearchNewListings(true)} />
        <ListingList
          isLoading={isLoading}
          listings={listings}
          resetPage={toResetPage}
          setResetPage={() => {
            setToResetPage(false);
          }}
        />
      </div>
    </div>
  );
};

export default Marketplace;
