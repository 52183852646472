import React from "react";

import classes from "./PlateListingInfo.module.scss";

const PlateListingInfo = ({ listingPlateInfo, setListingPlateInfo }) => {
  const handleChange = (e) => {
    setListingPlateInfo({ ...listingPlateInfo, [e.target.name]: e.target.value });
  };

  return (
    <div className={classes.car_container}>
      <div className={classes.title_block}>
        <p className={classes.label}>Listing title:</p>
        <input
          type="text"
          name="title"
          value={listingPlateInfo.title}
          className={classes.car_container}
          placeholder="TLC plates for rent by owner"
          onChange={(e) => handleChange(e)}
        />
      </div>

      <div className={classes.title_block}>
        <p className={classes.label}>Short description (Optional):</p>
        <textarea
          rows="3"
          name="description"
          value={listingPlateInfo.description}
          onChange={handleChange}
          className={classes.car_container}
          placeholder="Hello, I have TLC plates. Anyone interested in having it please contact me."
        />
      </div>

      <div className={classes.price_block}>
        <p className={classes.label}>Asking price:</p>

        <div className={classes.price_block__inputs}>
          <input
            type="number"
            name="listingPrice"
            value={listingPlateInfo.listingPrice}
            className={classes.car_container}
            placeholder="$ 450"
            onChange={handleChange}
          />
          <div className={classes.price_block__inputs__radio}>
            <div className={classes.price_block__inputs__radio__radio_block}>
              <input
                className={classes.car_container}
                type="radio"
                value="weekly"
                name="paymentPeriod"
                checked={listingPlateInfo.paymentPeriod === 'weekly'}
                onChange={handleChange}
              />
              <p>Weekly</p>
            </div>
            <div className={classes.price_block__inputs__radio__radio_block}>
              <input
                className={classes.car_container}
                type="radio"
                value="monthly"
                name="paymentPeriod"
                checked={listingPlateInfo.paymentPeriod === 'monthly'}
                onChange={handleChange}
              />
              <p>Monthly</p>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.title_block}>
        <p className={classes.label}>Vehicle identification:</p>
        <input
          type="text"
          name="carNumber"
          value={listingPlateInfo.carNumber}
          className={classes.car_container}
          placeholder="VIN or DMV plate number"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default PlateListingInfo;
