import React from 'react'
import GetInTouch from './GetInTouch';

import classes from './HomeContainer.module.scss';
import MainSection from './MainSection';
import StepsSection from './StepsSection';
import WelcomeSection from './WelcomeSection';

const HomeContainer = () => {
  return (
    <div className={classes.container}>
        <WelcomeSection />
        <MainSection />
        <StepsSection />
        <GetInTouch />
    </div>
  )
}

export default HomeContainer