import React from "react";
import ListingCard from "../listing/ListingCard";

import classes from "./SavedTab.module.scss";

const AdsTab = ({ userListings, userData }) => {
  return (
    <div className={classes.container}>
      {userListings.length !== 0 &&
        userListings.map((listing) => <ListingCard 
          key={listing.listing_id} 
          listing={listing} 
          userData={userData}
          mode={"dashboard"}
        />
        )}

      {userListings.length === 0 && <p>Sorry, listings not found</p>}
    </div>
  );
};
export default AdsTab;
