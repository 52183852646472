import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import classes from "./OrderItem.module.scss";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";

import {
  incrementCartProductCounter,
  decrementCartProductCounter,
  setCartProductCounter,
  addToCartTotalPrice,
  minusFromCartTotalPrice,
} from "./../../redux/slices/cartSlice";

const OrderItem = ({ order, updateItem }) => {
  const [checked, setChecked] = useState(true);
  const [subTotal, setSubTotal] = useState(order.price);

  const cartProductCount = useSelector((state) => state.cart.cartProductCount);
  const dispatch = useDispatch();

  // Update if price or quantity canged
  useEffect(() => {
    setSubTotal(parseInt(order.quantity) * parseFloat(order.price));
  }, [order.quantity, order.price]);

  const handleChange = () => {
    if (!checked) {
      // Selected product to cart payment
      dispatch(addToCartTotalPrice(order.price * order.quantity));
      updateItem({ type: "SELECT_ORDER", id: order.id });

    } else {
      // Unselected product from cart payment
      dispatch(minusFromCartTotalPrice(order.price * order.quantity));
      updateItem({ type: "UNSELECT_ORDER", id: order.id });
    }
    setChecked(!checked);
  };

  const onIncreaseQuantityHandler = () => {
    if (checked) {
      updateItem({ type: "INCREMENT", id: order.id, quantity: order.quantity });
      dispatch(incrementCartProductCounter());
      dispatch(addToCartTotalPrice(parseFloat(order.price)));
    }
  };

  const onDecreaseQuantityHandler = () => {
    if (order.quantity >= 2 && checked) {
      updateItem({ type: "DECREMENT", id: order.id, quantity: order.quantity });
      dispatch(decrementCartProductCounter());
      dispatch(minusFromCartTotalPrice(parseFloat(order.price)));
    }
  };

  const onDeleteProductHandler = () => {
    updateItem({ type: "DELETE", id: order.id });
    dispatch(setCartProductCounter(cartProductCount - order.quantity));
    dispatch(
      minusFromCartTotalPrice(
        parseInt(order.quantity) * parseFloat(order.price)
      )
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.deleteProduct} onClick={onDeleteProductHandler}>
        <FiTrash2 />
      </div>
      <input type="checkbox" checked={checked} onChange={handleChange} />
      <div className={classes.box}>
        <div className={classes.box__row}>
          <p className={classes.title}>{order.title}</p>
        </div>

        <div className={classes.box__row}>
          <p>
            <span className={classes.price}>$</span>
            <span className={classes.price}>
              {parseFloat(order.price).toFixed(2)}
            </span>
            <span> per unit</span>
          </p>
        </div>

        <div className={classes.box__row}>
          <div className={classes.box__row__quantity}>
            <div className={checked ? classes.btn : classes.btnDisabled} onClick={onDecreaseQuantityHandler}>
              <AiOutlineMinusCircle />
            </div>
            <p className={classes.box__row__quantity__numbers}>
              {order.quantity}
            </p>
            <div className={checked ? classes.btn : classes.btnDisabled} onClick={onIncreaseQuantityHandler}>
              <AiOutlinePlusCircle />
            </div>
          </div>

          <div className={classes.box__row__subtotal}>
            <span>Subtotal:</span>{" "}
            <span className={classes.price}>
              <span>$</span>
              {parseFloat(subTotal).toFixed(2)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderItem;
