import React, {useState} from 'react'
import StepContent from '../components/steps/StepContent';
import StepProgressStatusBar from '../components/steps/StepProgressStatusBar'

const StepByStep = () => {

  const [currentStep, setCurrentStep] = useState(1);

  const setStep = (step) =>{
    setCurrentStep(step);
  }

  return (
    <div>
      <StepProgressStatusBar 
        currentStep={currentStep}
        setStep={(newStep) => {setStep(newStep)}} />

      <StepContent 
        currentStep={currentStep}
        setStep={(newStep) => {setStep(newStep)}} />
    </div>
  )
}

export default StepByStep