import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import axios from "axios";

import ENV from './../env';
import ExamSection from '../components/exam/ExamSection';
import Popup from '../components/Popup';
import popupImage from '../images/popupBeginExam.svg';
import popupImageCompleate from '../images/done.svg';
import popupImageUnsuccessfullyCompleate from '../images/undone.svg';
import Spinner from '../components/Spinner';

const ExamPage = () => {

  const navigate = useNavigate();

  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [questionsData, setquestionsData] = useState(null);
  const [correctNumber, setCorrectNumber] = useState(0);

  useEffect(() => {
    axios.get(ENV.BASE_URL + "/exam/questions").then((response) => {
      setquestionsData(response.data);
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      {/* PopUp window on the begining of Exam */}
      {!isLoading && isPopupOpen && <Popup 
        handleClose={() => {setIsPopupOpen(false)}}
        title="Before you start"
        image={popupImage}
        content="For better understanding and for a more productive learning process we highly recommend 
                  using the 5 Borough TLC Map while passing the exam." />}

      {/* PopUp window in the compleat Exam */}
      {isCompleted && <Popup 
        handleClose={() => {
          setIsCompleted(false);
          navigate('/');
        }}
        title={correctNumber > 60 ? "Congratulation!" : "Exam failed!"}
        image={correctNumber > 60 ? popupImageCompleate : popupImageUnsuccessfullyCompleate}
        content={"Your score is  " + correctNumber + " of 80."} />}
      
      {/* Show loading spinner while fetching data */}
      {isLoading && <Spinner />}
      
      {/* Exam section */}
      {!isLoading && <ExamSection 
        data={questionsData.data}
        correctNumber={() => {setCorrectNumber(correctNumber + 1)}}
        onComplete={() => {setIsCompleted(true)}} />}
    </>
  )
}

export default ExamPage