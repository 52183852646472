import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Select from "react-select";
import debounce from "lodash.debounce";

import classes from "./ListingFilter.module.scss";
import ENV from "../../env";
import { setListingFilter } from "../../redux/slices/listingFilterSlice";


const ListingFilter = ({ searchNewListings }) => {
  const isInit = useRef(true);

  // Redux
  const dispatch = useDispatch();
  const savedFilters = useSelector((state) => state.listingFilters.filters);

  const [suggestedSearchError, setSuggestedSearchError] = useState(false);
  const [isSuggestedSearching, setIsSuggestedSearching] = useState(false);
  const [suggestedSearch, setSuggestedSearch] = useState([]);
  const [isSelectedLocation, setIsSelectedLocation] = useState(false);

  const [userInputLocation, setUserInputLocation] = useState(
    savedFilters?.location ? savedFilters.location : ""
  );

  const [userInputLocationZipCode, setUserInputLocationZipCode] = useState(
    savedFilters?.location_zip ? savedFilters.location_zip : null
  );
  const [userInputLocationCity, setUserInputLocationCity] = useState(
    savedFilters?.location_city ? savedFilters.location_city : null
  );
  const [userInputLocationState, setUserInputLocationState] = useState(
    savedFilters?.location_state ? savedFilters.location_state : null
  );

  // Listing category select
  const [listingCategory, setListingCategory] = useState(
    savedFilters?.category
      ? checkSavedCategory(savedFilters.category)
      : {
          value: "all",
          label: "All",
        }
  );
  const listingOptions = [
    { value: "all", label: "All" },
    { value: "cars", label: "Cars" },
    { value: "plates", label: "Plates" },
  ];

  // Listing prices
  const [minPrice, setMinPrice] = useState(
    savedFilters?.price_min ? savedFilters.price_min : ""
  );
  const [maxPrice, setMaxPrice] = useState(
    savedFilters?.price_max ? savedFilters.price_max : ""
  );

  // Car years
  const [minYear, setMinYear] = useState(
    savedFilters?.car_year_min ? savedFilters.car_year_min : ""
  );
  const [maxYear, setMaxYear] = useState(
    savedFilters?.car_year_max ? savedFilters.car_year_max : ""
  );

  // Car make
  const [carMake, setCarMake] = useState(
    savedFilters?.car_make_value && savedFilters.car_make_value !== "all"
      ? { value: savedFilters.car_make_value, label: savedFilters.car_make }
      : { value: "all", label: "All" }
  );

  // eslint-disable-next-line
  const [carMakeOptions, setCarMakeOptions] = useState([
    { value: "all", label: "All" },
  ]);

  // Car model
  const [carModel, setCarModel] = useState(
    savedFilters?.car_model_value && savedFilters.car_model_value !== "all"
      ? { value: savedFilters.car_model_value, label: savedFilters.car_model }
      : { value: "all", label: "All" }
  );
  // eslint-disable-next-line
  const [carModelOptions, setCarModelOptions] = useState([
    { value: "all", label: "All" },
  ]);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      // backgroundColor: "red" ,
      // color: "#FFF",
      fontSize: 18,
      border: 1,
      boxShadow: "none",
    }),
    menuList: (styles) => ({
      ...styles,
      // background: 'papayawhip',
      // width: 155
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused ? "#f6f6f6" : isSelected ? "#f6f6f6" : undefined,
      color: "#1d1d1d",
      zIndex: 1,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 1,
      // color: 'white'
    }),
    singleValue: (provided) => ({ ...provided, color: "#1d1d1d" }),

    dropdownIndicator: (base) => ({
      ...base,
      color: "#1d1d1d",
    }),
  };

  function checkSavedCategory(category) {
    if (category === "cars") {
      return { value: "cars", label: "Cars" };
    } else if (category === "plates") {
      return { value: "plates", label: "Plates" };
    }
  }

  const onSuggestedClick = (e) => {
    setIsSelectedLocation(true);

    if (e?.zip_code) {
      setUserInputLocation(e.zip_code);
    } else {
      setUserInputLocation(e.city);
    }

    // Set location
    if (e?.zip_code) {
      setUserInputLocationZipCode(e.zip_code);
      setUserInputLocationCity(null);
      setUserInputLocationState(null);
    } else {
      setUserInputLocationZipCode(null);
      setUserInputLocationCity(e.city);
      setUserInputLocationState(e.state);
    }

    // Close suggested list
    setSuggestedSearch([]);
  };

  const onLocationDebounceHandler = useMemo(
    () =>
      debounce((location) => {
        setIsSuggestedSearching(true);

        let param = {
          limit: 5,
        };
        let isNum = /^\d+$/.test(location);

        if (isNum) {
          param["zip_code"] = location;
        } else {
          param["city"] = location;
        }

        // Fetch all sugestion
        axios
          .get(ENV.BASE_URL + "/listing/suggested-locations", {
            params: param,
          })
          .then((response) => {
            setSuggestedSearch(response.data.data);
            setIsSuggestedSearching(false);
          })
          .catch((err) => {
            setIsSuggestedSearching(false);

            // Show error
            if (err.response.status === 400) {
              // Location not found error
              setSuggestedSearchError(true);
              setSuggestedSearch([]);
            }
          });
      }, 1000),
    [setSuggestedSearch]
  );

  // Init - load data
  useEffect(() => {
    if (isInit.current) {
      isInit.current = false;

      // Load all makes
      axios.get(ENV.BASE_URL + "/cars/makes").then((makeResponse) => {
        // Convert response to Option standart
        const resultMakes = makeResponse.data.map((obj) => {
          return renameKeys({ make_id: "value", make_name: "label" }, obj);
        });

        // Add default value
        resultMakes.unshift({ value: "all", label: "All" });

        // Set result Options
        setCarMakeOptions(resultMakes);
      });
    }
  }, []);

  // Fetch Car Models when make is selected
  useEffect(() => {
    if (carMake.value !== "all") {
      axios
        .get(ENV.BASE_URL + "/cars/models-by-make-id?make-id=" + carMake.value)
        .then((responseModel) => {
          // Convert response to Option standart
          const resultModels = responseModel.data.map((obj) => {
            return renameKeys({ model_id: "value", model_name: "label" }, obj);
          });

          // Add default value
          resultModels.unshift({ value: "all", label: "All" });

          // Set result Options
          setCarModelOptions(resultModels);
        });
    }
  }, [carMake]);

  // Use Effect to handle user input location
  useEffect(() => {
    // Remove error if present
    if (suggestedSearchError) {
      setSuggestedSearchError(false);
    }

    if (isSelectedLocation) {
      // Asign selected value if selected
      setIsSelectedLocation(false);
      setSuggestedSearch([]);
    } else {
      // Do suggestion search
      onLocationDebounceHandler(userInputLocation);
    }

    // eslint-disable-next-line
  }, [userInputLocation]);

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => {
      onLocationDebounceHandler.cancel();
    };
  }, [onLocationDebounceHandler]);

  // Handle search filter
  const onSearchClickHandler = () => {
    let filter = {};

    // Location
    if (userInputLocation && userInputLocationZipCode) {
      filter.location = userInputLocation;
      filter.location_zip = userInputLocationZipCode;
      filter.location_city = userInputLocationCity;
      filter.location_state = userInputLocationState;
    } else if (userInputLocation && !userInputLocationZipCode) {
      filter.location_city = userInputLocationCity;
      filter.location_state = userInputLocationState;
      filter.location = userInputLocation;
    }

    // Category
    if (listingCategory && listingCategory.value !== "all") {
      filter.category = listingCategory.value;
    }

    // Min price
    if (minPrice) {
      filter.price_min = minPrice;
    }

    // Max price
    if (maxPrice) {
      filter.price_max = maxPrice;
    }

    // Min Car Year
    if (listingCategory.value === "cars" && minYear) {
      filter.car_year_min = minYear;
    }

    // Min Car Year
    if (listingCategory.value === "cars" && maxYear) {
      filter.car_year_max = maxYear;
    }

    // Car Make
    if (listingCategory.value === "cars" && carMake) {
      filter.car_make = carMake.label;
      filter.car_make_value = carMake.value;
    }

    // Car Model
    if (
      listingCategory.value === "cars" &&
      carMake.value !== "all" &&
      carModel
    ) {
      filter.car_model = carModel.label;
      filter.car_model_value = carModel.value;
    }

    dispatch(setListingFilter(filter));
    searchNewListings();
  };

  let renameKeys = (keysMap, object) =>
    Object.keys(object).reduce(
      (acc, key) => ({
        ...acc,
        ...{ [keysMap[key] || key]: object[key] },
      }),
      {}
    );

  return (
    <div className={classes.container}>
      <div className={classes.filterByLocationBlock}>
        <p className={classes.title}>Filter by location:</p>
        <div className={classes.filterByLocationBlock__inputSection}>
          <input
            type="text"
            value={userInputLocation}
            onChange={(e) => {
              setUserInputLocation(e.target.value);
            }}
            placeholder="City or Zip Code"
          />

          {/* Progres status block */}
          {isSuggestedSearching && (
            <div
              className={
                classes.filterByLocationBlock__inputSection__suggestedSection
              }
            >
              <p>Searching...</p>
            </div>
          )}

          {/* Error block */}
          {suggestedSearchError && userInputLocation.length >= 1 && (
            <div
              className={
                classes.filterByLocationBlock__inputSection__suggestedSection
              }
            >
              <p>Location not found</p>
            </div>
          )}

          {/* Result search block */}
          {!isSuggestedSearching && suggestedSearch.length >= 1 && (
            <div
              className={
                classes.filterByLocationBlock__inputSection__suggestedSection
              }
            >
              {suggestedSearch.map((suggest, i) => (
                <p key={i} onClick={() => onSuggestedClick(suggest)}>
                  {suggest.zip_code
                    ? suggest.zip_code + ", " + suggest.city
                    : suggest.city}
                  , {suggest.state}
                </p>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className={classes.categoryBlock}>
        <p className={classes.title}>Category:</p>
        <Select
          styles={colourStyles}
          value={listingCategory}
          onChange={setListingCategory}
          isSearchable={false}
          inputProps={{ readOnly: true }}
          options={listingOptions}
        />
      </div>

      <div className={classes.filterByPriceBlock}>
        <p className={classes.title}>Price:</p>
        <div className={classes.filterByPriceBlock__inputRow}>
          <input
            type="number"
            value={minPrice}
            onChange={(e) => {
              setMinPrice(e.target.value);
            }}
            placeholder="Min"
          />
          <input
            type="number"
            value={maxPrice}
            onChange={(e) => {
              setMaxPrice(e.target.value);
            }}
            placeholder="Max"
          />
        </div>
      </div>

      {listingCategory.value === "cars" && (
        <div className={classes.carListingBlock}>
          {/* Year */}
          <p className={classes.title}>Year:</p>
          <div className={classes.carListingBlock__row}>
            <div className={classes.carListingBlock__row__inputs}>
              <input
                type="number"
                value={minYear}
                onChange={(e) => {
                  setMinYear(e.target.value);
                }}
                placeholder="Min"
              />
              <input
                type="number"
                value={maxYear}
                onChange={(e) => {
                  setMaxYear(e.target.value);
                }}
                placeholder="Max"
              />
            </div>
          </div>

          {/* Make */}
          <div className={classes.carListingBlock__row}>
            <p className={classes.title}>Make:</p>
            <Select
              styles={colourStyles}
              value={carMake}
              onChange={setCarMake}
              options={carMakeOptions}
            />
          </div>

          {/* Model */}
          {carMake && carMake.value !== "all" && (
            <div className={classes.carListingBlock__row}>
              <p className={classes.title}>Model:</p>
              <Select
                styles={colourStyles}
                value={carModel}
                onChange={setCarModel}
                options={carModelOptions}
              />
            </div>
          )}
        </div>
      )}

      {/* Search button */}
      <div className={classes.searchBtnBlock}>
        <button onClick={onSearchClickHandler}>Search</button>
      </div>
    </div>
  );
};

export default ListingFilter;
