import React from 'react';

import spennerSvg from '../images/spinner.svg';

import classes from './Spinner.module.scss';

const Spinner = () => {
  return (
    <div className={classes.spinner_container}>
      <img src={spennerSvg} alt="Spinner" />
    </div>
  )
}

export default Spinner