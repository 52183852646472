import React, { useState } from "react";
import classes from "./SmallImageCard.module.scss";

const SmallImageCard = (props) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleMouseOver = () => {
    setIsMouseOver(true);
  };
  const handleMouseOut = () => {
    setIsMouseOver(false);
  };
  return (
    <div
      className={classes.container}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {isMouseOver && (
        <div className={classes.container__overBlock}>
          <div
            onClick={() => {
              props.onSetMainImage(props.url);
            }}
          >
            <p>Set as main</p>
          </div>
          <div
            onClick={() => {
              props.onDeleteImage(props.url);
            }}
          >
            <p>Delete</p>
          </div>
        </div>
      )}
      <img src={props.url} alt="img" />
    </div>
  );
};

export default SmallImageCard;
