import React, {useState} from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import ENV from '../../../env';

const CreateSetupIntentForm = ({handleClose, fromURL, state}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let baseURL = '';

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (ENV.BASE_URL === "https://api.drivetlc.com"){
      baseURL = 'https://drivetlc.com';
    } 
    else if (ENV.BASE_URL === "https://dev-api.drivetlc.com") {
      baseURL = 'https://dev.drivetlc.com';
    } else {
      baseURL = 'http://localhost:3000';
    }

    const {error} = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        // TODO: Need to make exact path to root
        return_url: baseURL + '/success-setup-intent?from='+fromURL+'&listingId='+state?.listingId,
      }
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Close dialog button */}
      <div style={{ display: "flex", alignSelf: "flex-end", margin: "10px 0", cursor: "pointer" }}>
        <h3 onClick={handleClose}>X</h3>
      </div>

      {/* Stripe payment form */}
      <PaymentElement />

      {/* Submit button */}
      <div style={{ display: "flex", justifyContent: "center", margin: "10px 0" }}>
        <button disabled={!stripe}>Submit</button>
      </div>
      
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  )
};

export default CreateSetupIntentForm;