import React, {useState} from 'react';
import AdsTab from './AdsTab';
import SavedTab from './SavedTab';
import SettingsTab from './SettingsTab';

import classes from './TabsSection.module.scss';

const TabsSection = (props) => {

  const [activeTab, setActiveTab] = useState('settings');
  
  return (
    <div className={classes.container}>
      {/* Tab links */}
      <div className={classes.container_tab}>
        <button 
          className={`${activeTab === 'settings' && classes.container_tabcontent_selected}`} 
          onClick={()=>{setActiveTab('settings')}}>Settings</button>

        <button
          className={`${activeTab === 'marketplace' && classes.container_tabcontent_selected}`}
          onClick={()=>{setActiveTab('marketplace')}}>Marketplace ads</button>

        <button 
          className={`${activeTab === 'questions' && classes.container_tabcontent_selected}`} 
          onClick={()=>{setActiveTab('questions')}}>Saved questions</button>
      </div>

      {/* Tab content */}
      {activeTab === 'settings' &&
        <div id="settings" className={classes.container_tabcontent}>
          <SettingsTab userPaymentMethods={props.userPaymentMethods}/>
        </div>
      }

      {activeTab === 'marketplace' &&
        <div id="marketplace" className={classes.container_tabcontent}>
          <h3>Your Ads</h3>
          <AdsTab 
            userListings={props.userListings} userData={props.userData}/>
        </div>
      }

      {activeTab === 'questions' &&
        <div id="questions" className={classes.container_tabcontent}>
          <h3>Saved questions</h3>
          <SavedTab />
        </div>
      }
    </div>
  )
}

export default TabsSection