import React from 'react';
import { Link } from "react-router-dom";

import classes from './Footer.module.scss';

const Footer = () => {

  return (
    <div className={classes.footer}>
        <p>© 2024 DriveTLC.com</p>
        <Link to="/terms">Terms and Conditions</Link>
    </div>
  )
}

export default Footer