import React, { useEffect, useState } from "react";
import axios from "axios";

import classes from "./Shop.module.scss";

import ENV from "../../env";
import ProductItem from "./ProductItem";
import Spinner from "../Spinner";

const Shop = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    axios
      .get(ENV.BASE_URL + "/products")
      .then((response) => {
        setAllProducts(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {/* Show loading spinner while fetching data */}
      {isLoading && <Spinner />}

      {/* Check for not products */}
      {allProducts.length === 0 && (
        <div className={classes.container__error}>
          <h2>
            Sorry, no active products at this time.
          </h2>
          <p>Please visit us later</p>
        </div>
      )}

      {!isLoading && (
        <div className={classes.container}>
          {allProducts.map((product, i) => {
            return (
              <div className={classes.mainSection} key={i}>
                <ProductItem product={product} />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Shop;
