import React from 'react';
import CreateListing from '../components/listing/CreateListing';

const CreateListingPage = () => {

  return (
    <div>
      <CreateListing />
    </div>
  )
}

export default CreateListingPage