import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';

import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { BsCartCheck } from "react-icons/bs";

import classes from "./Header.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { AccountContext } from "./auth/account";
import ENV from "../env";
import { setCartProductCounter } from '../redux/slices/cartSlice';

const Header = () => {
  const { getSession, logout } = useContext(AccountContext);
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLogedIn, setIsLogedIn] = useState(false);
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Redux
  const cartProductCount = useSelector((state) => state.cart.cartProductCount);
  const dispatch = useDispatch();

  // Use effect for user session
  useEffect(() => {
    getSession()
      .then((session) => {
        setIsLogedIn(true);
        axios
          .get(ENV.BASE_URL + "/users/cart", {
            headers: { Authorization: `Bearer ${session.idToken.jwtToken}` },
          })
          .then((response) => {
            dispatch(setCartProductCounter(response.data.map(item => item.quantity).reduce((prev, next) => prev + next)));
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        setIsLogedIn(false);
      });

    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [getSession, dispatch]);

  useEffect(() => {
    if (size.width > 768 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  const onLoginPage = () => {
    setMenuOpen(!menuOpen);
    navigate("/login");
  };

  const goToCartPage = () => {
    setMenuOpen(!menuOpen);
    navigate("/cart");
  }
  
  return (
    <header className={classes.header}>
      <div className={classes.header__content}>
        <Link to="/" className={classes.header__content__logo}>
          DriveTLC
        </Link>

        <nav
          className={`${classes.header__content__nav} ${
            menuOpen && size.width < 768 ? classes.isMenu : ""
          }`}
        >
          <ul>
            {/* <li>
                        <Link to='/' onClick={() => setMenuOpen(!menuOpen)}>Home</Link>
                    </li> */}
            <li>
              <Link to="/marketplace" onClick={() => setMenuOpen(!menuOpen)}>
                Marketplace
              </Link>
            </li>
            <li>
              <Link to="/shop" onClick={() => setMenuOpen(!menuOpen)}>
                Shop
              </Link>
            </li>
            <li>
              <Link to="/exam" onClick={() => setMenuOpen(!menuOpen)}>
                Exam
              </Link>
            </li>
            {isLogedIn && (
              <>
                <li>
                  <Link to="/dashboard" onClick={() => setMenuOpen(!menuOpen)}>
                    Dashboard
                  </Link>
                </li>
                <li className={classes.header__cartBox} onClick={goToCartPage}>
                  <BsCartCheck />
                  <p className={classes.header__cartBox__numbers}>{cartProductCount}</p>
                </li>
              </>
            )}
          </ul>

          {/* Check if user loged in or not */}
          {!isLogedIn && <button onClick={onLoginPage}>Sign in</button>}
          {isLogedIn && <button onClick={logout}>Sign Out</button>}
        </nav>
        <div className={classes.header__content__toggle}>
          {menuOpen ? (
            <AiOutlineClose
              onClick={() => setMenuOpen(!menuOpen)}
              style={{ position: "fixed", top: "2rem", right: "1.3rem" }}
            />
          ) : (
            <BiMenuAltRight onClick={() => setMenuOpen(!menuOpen)} />
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
