import {React, useState, useRef, useEffect, useContext} from "react";
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import axios from "axios";
import { toast } from 'react-toastify';

import classes from "./ListingCard.module.scss";
import carDefaultImg from "../../images/listing_default_car.svg";
import plateDefaultImg from "../../images/listing_default_plates.png";
import viewImg from "../../images/icons/view.svg";

import { AccountContext } from "../auth/account";
import ENV from "../../env";

const ListingCard = (props) => {
  const { getSession } = useContext(AccountContext);
  const navigate = useNavigate();
  const isInit = useRef(true);

  const [listingStatus, setListingStatus] = useState({});
  
  const statusOptions = [
    { value: "ACTIVE", label: "Active" },
    { value: "INACTIVE", label: "Deactivate" }
  ];

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      fontSize: 12,
      border: 1,
      boxShadow: "none",
    }),
    menuList: (styles) => ({
      ...styles,
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused ? "#f6f6f6" : isSelected ? "#f6f6f6" : undefined,
      color: "#1d1d1d",
      zIndex: 1,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 1,
    }),
    singleValue: (provided) => ({ ...provided, color: "#1d1d1d" }),

    dropdownIndicator: (base) => ({
      ...base,
      color: "#1d1d1d",
    }),
  };

  useEffect(() => {
    if (isInit.current) {
      isInit.current = false;
      
      if (props.listing.status === "ACTIVE") {
        setListingStatus({ value: props.listing.status, label: "Active" });
      }
      else if (props.listing.status === "INACTIVE") {
        setListingStatus({ value: props.listing.status, label: "Deactivate" });
      }
    }
    // eslint-disable-next-line
  }, []);

  const onListingHandler = () => {
    if (props.listing.status === "DRAFT") {
      navigate("/checkout", {
        state: { listingId: props.listing.listing_id, fromURL: "/create-listing" },
      });
    }
    else if (props.listing.status === "UNDER_REVIEW") {
      toast.error('Listing is under review. Wait for approval or contact us directly.', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
    else {
      navigate('/listing/'+ props.listing.listing_id);
    }
  };

  const formatDate = (dateString) => {
    // Validate date
    if (dateString === 'NaT') return "Not posted yet";

    if (props.listing.status === "UNDER_REVIEW") return "Waiting for review";

    const options = { year: "numeric", month: "long", day: "numeric"}
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const onChangeStatus = (e) => {
    // Make API call to update listing status
    toast.info("Updating listing status...", {
      position: "top-center",
      autoClose: 1000,
      theme: "colored"
    });
    // Get user session
    getSession()
      .then((session) => {
      axios.post(ENV.BASE_URL  + "/listing/change-status", {
        listing_id: props.listing.listing_id,
        listing_status: e.value
      }, {
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          setListingStatus(e);
          // Show success message
          toast.success("Listing status updated successfully", {
            position: "top-center",
            autoClose: 3000,
            theme: "colored",
          });
        }
      });
    });
  }

  return (
    <div className={classes.container} onClick={onListingHandler}>
      {/* Lable ready to checkout listings */}
      {props.listing.status === "DRAFT" && props.mode === "dashboard" && (
        <div className={classes.toCheckout}>Review & Checkout</div>
      )}

      {/* Lable unter review listings */}
      {props.listing.status === "UNDER_REVIEW" && (
        <div className={classes.statusUnderReview}>Under Review</div>
      )}

      {(props.listing.status === "INACTIVE" || props.listing.status === "ACTIVE") && props.mode === "dashboard" && (
        <div className={classes.statusDropDown} onClick={(e) => e.stopPropagation()}>
          {/* View container */}
          <div className={classes.viewContainer}>
            <span>{props.listing.view_count}</span>
            <img src={viewImg} alt="view" />
          </div>
          
          {/* Status drop down */}
          <Select
            className={classes.container__step1__categoryBlock__select}
            value={listingStatus}
            styles={colourStyles}
            onChange={onChangeStatus}
            isSearchable={false}
            inputProps={{ readOnly: true }}
            options={statusOptions}
          />
        </div>
      )}

      {/* View container for marketplace page */}
      {props.listing.status === "ACTIVE" && props.mode === "listing" && (
        // Add justihy-content: end to the parent div
        <div className={`${classes.statusDropDown} ${classes.viewMarketplaceContainer}`} >
          {/* View container */}
          <div className={classes.viewContainer}>
            <span>{props.listing.view_count}</span>
            <img src={viewImg} alt="view" />
          </div>
        </div>
      )}

      {/* Image car */}
      {props.listing.category === "cars" && (
        <div className={classes.leftSide}>
          {props.listing?.images[0] ? (
            <img
              src={
                "https://tlc-listing-images.s3.amazonaws.com/" +
                props.listing.listing_id +
                "/" +
                props.listing.images[0]
              }
              alt="listing img"
            />
          ) : (
            <img src={carDefaultImg} alt="listing default" />
          )}

          {props.listing.vin && (
            <p className={classes.leftSide__verifyd}>Verifyd</p>
          )}
        </div>
      )}

      {/* Image plate */}
      {props.listing.category === "plates" && (
        <div className={classes.leftSide}>
          {props.listing?.images[0] ? (
            <img
              src={
                "https://tlc-listing-images.s3.amazonaws.com/" +
                props.listing.listing_id +
                "/" +
                props.listing.images[0]
              }
              alt="listing img"
            />
          ) : (
            <img src={plateDefaultImg} alt="listing default" />
          )}

          {props.listing.vin && (
            <p className={classes.leftSide__verifyd}>Verifyd</p>
          )}
        </div>
      )}

      <div className={classes.rightSide}>
        <p className={classes.title}>{props.listing.title}</p>

        <div className={classes.priceBlock}>
          <p>
            <span className={classes.priceBlock__price}>
              ${props.listing.price}
            </span>{" "}
            {props.listing.payment_period}
          </p>
        </div>

        {props.listing.category === "cars" && <div className={classes.carInfoBlock}>
          <div className={classes.carInfoBlock__row}>
            <p className={classes.carInfoBlock__colOne}>
              Make: {props.listing.car_make}
            </p>
            <p className={classes.carInfoBlock__colTwo}>
              Model: {props.listing.car_model}
            </p>
          </div>

          <div className={classes.carInfoBlock__row}>
            <p className={classes.carInfoBlock__colOne}>
              Year: {props.listing.car_year}
            </p>
            <p className={classes.carInfoBlock__colTwo}>
              Fuel: {props.listing?.car_fuel ? props.listing.car_fuel : "--"}
            </p>
          </div>
        </div>}

        <div className={classes.locationBlock}>
          <p>Location: {" "}
            {props.listing.location?.zip_code 
            ? props.listing.location.city + ", " + props.listing.location.state + " " + props.listing.location.zip_code
            : props.listing.location.city + ", " + props.listing.location.state}
          </p>
        </div>

        <div className={classes.listingInfoBlock}>
          <p>Posted: {formatDate(props.listing?.timestamp)}</p>
        </div>
      </div>
    </div>
  );
};

export default ListingCard;
