import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const SetupIntentRedirect = () => {
  const navigate = useNavigate();
  const search = useLocation().search;

  useEffect(() => {
    const fromURL = new URLSearchParams(search).get("from");
    
    if (fromURL === "dashboard") {
      navigate("/dashboard");
    }
    else if (fromURL === "checkout") {
      const listingId = new URLSearchParams(search).get("listingId");
      navigate("/checkout", {state:{listingId:listingId}});
    }
    else if (fromURL === "cart") {
      navigate("/cart");
    }
    else if (fromURL === "exam-checkout") {
      navigate("/exam-checkout");
    }
  }, [navigate, search]);
};

export default SetupIntentRedirect;
