import Layout from "./components/Layout";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import MarketplacePage from "./pages/MarketplacePage";
import ExamPage from "./pages/ExamPage";
import LoginPage from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import StepByStep from "./pages/StepByStep";
import SetupIntentRedirect from "./components/dashboard/SetupIntentRedirect";
import CreateListingPage from "./pages/CreateListingPage";
import CheckoutPage from "./pages/checkout/CheckoutPage";
import ListingPage from "./pages/ListingPage";
import ShopPage from "./pages/ShopPage";
import CartPage from "./pages/CartPage";
import TermsPage from "./pages/TermsPage";
import FederatedAuth from "./components/auth/federated_auth";
import ExamCheckout from "./components/exam/ExamCheckout";

function App() {
  return (
    <Layout>
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route exact path="/marketplace" element={<MarketplacePage />} />
          <Route exact path="/exam" element={<ExamPage/>} />
          <Route exact path="/dashboard" element={<Dashboard/>} />
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/steps" element={<StepByStep />} />
          <Route exact path="/create-listing" element={<CreateListingPage />} />
          <Route exact path="/listing/:listing_id" element={<ListingPage />} />
          <Route exact path="/checkout" element={<CheckoutPage />} />
          <Route exact path="/shop" element={<ShopPage />} />
          <Route exact path="/cart" element={<CartPage />} />
          <Route exact path="/terms" element={<TermsPage />} />
          <Route exact path="/auth" element={<FederatedAuth />} />
          <Route exact path="/exam-checkout" element={<ExamCheckout />} />

          {/* Success SetupIntent */}
          <Route exact path="/success-setup-intent" element={<SetupIntentRedirect />} />
        </Routes>
    </Layout>
  );
}

export default App;
