import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import ENV from "../env";

import classes from "./ListingPage.module.scss";

import plateDefaultImg from "../images/listing_default_plates.png";
import carDefaultImg from "../images/listing_default_car.svg";

import Spinner from "../components/Spinner";
import Listing from "../components/listing/Listing";
import { AccountContext } from "../components/auth/account";

const ListingPage = () => {
  const { listing_id } = useParams();
  const { getSession } = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(true);
  const [listingData, setListingData] = useState({});
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const [numberOfImages, setNumberOfImages] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    setIsLoading(true);

    // Make API call to fetch listing info
    axios
      .get(ENV.BASE_URL + "/listing/" + listing_id)
      .then((response) => {
        // Check if request is success
        if (response.status === 200) {
          setListingData(response.data.data);
          setNumberOfImages(response.data.data?.images?.length);
          // Get user session
          getSession().then((session) => {
            if (session) {
              setUserId(session.sub);

              // Handle listing views
              handleListingViews(session.sub);
            }
          }).catch(() => {
            console.log("user not logged in");
            setUserId(null);
            
            // Handle listing views
            handleListingViews(null);
          });
          
          setIsLoading(false);
        } else {
          console.log("[ERROR]: Error happens while fetching user info.");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, [listing_id]);

  // Handle image change when click on arrow left or right
  const handleImageChange = (e) => {
    if (e === "right") {
      if (currentImage < numberOfImages - 1) {
        setCurrentImage(currentImage + 1);
      } else {
        setCurrentImage(0);
      }
    }

    if (e === "left") {
      if (currentImage > 0) {
        setCurrentImage(currentImage - 1);
      } else {
        setCurrentImage(numberOfImages - 1);
      }
    }
  };

  // Function to handle listing views
  const handleListingViews = (user_id) => {
    // Check user's cookie
    let visited_listings = Cookies.get("drivetlc_visited_listings");

    // If cookie is not set, set it
    if (visited_listings === undefined) {
      Cookies.set("drivetlc_visited_listings", [listing_id], { expires: 14 });
      
      // Send listing_id to backend and update views
      axios.post(ENV.BASE_URL + "/listing/" + listing_id + "/views", {
        user_id: user_id,
      }).then((response) => {
        console.log(response);
      }).catch((err) => {
        console.log(err);
      });

    } else {
      // If cookie is set, check if listing_id is in the cookie
      visited_listings = visited_listings.split(",");

      if (!visited_listings.includes(listing_id)) {
        // If listing_id is not in the cookie, add it to the cookie
        visited_listings.push(listing_id);

        Cookies.set("drivetlc_visited_listings", visited_listings);
        
        // Send listing_id to backend and update views
        axios.post(ENV.BASE_URL + "/listing/" + listing_id + "/views", {
          user_id: user_id,
        }).then((response) => {
          console.log(response);
        }).catch((err) => {
          console.log(err);
        });
      }
    }
  };

  return (
    <>
      {isLoading && <Spinner />}

      {!isLoading && isWindowOpen && (
        <div className={classes.full_window_container}>
          <div
            className={classes.full_window_container__close}
            onClick={() => setIsWindowOpen(false)}
          >
            {" "}
            x{" "}
          </div>

          <div className={classes.full_window_container__content}>
            {/* Arrow left */}
            <div
              className={classes.full_window_container__content__arrow_left}
              onClick={() => handleImageChange("left")}
            >
              {" "}
              {"<"}{" "}
            </div>

            {/* Arrow right */}
            <div
              className={classes.full_window_container__content__arrow_right}
              onClick={() => handleImageChange("right")}
            >
              {" "}
              {">"}{" "}
            </div>

            {/* Show default image for plates category */}
            {listingData.images?.length === 0 &&
              listingData?.category === "plates" && (
                <img src={plateDefaultImg} alt="Default plate" />
              )}

            {/* Show default image for car category */}
            {listingData.images?.length === 0 &&
              listingData?.category === "cars" && (
                <img src={carDefaultImg} alt="Default car" />
              )}

            {/* Main Image */}
            {listingData.images?.length !== 0 && (
              <img
                src={
                  "https://tlc-listing-images.s3.amazonaws.com/" +
                  listingData.listing_id +
                  "/" +
                  listingData.images[currentImage]
                }
                alt="Default"
              />
            )}
          </div>
        </div>
      )}

      {!isLoading && (
        <Listing
          listingData={listingData}
          userId={userId}
          openImage={() => {
            setIsWindowOpen(true);
          }}
        />
      )}
    </>
  );
};

export default ListingPage;
