import React from 'react';
import classes from './StepsSection.module.scss';
import stepsImg from '../../images/steps.svg';
import { Link } from 'react-router-dom';

const StepsSection = () => {
  return (
    <div className={classes.section}>

        <div className={classes.section__contentSide}>
            <h1>TLC Step By Step</h1>
            <h3>The best guideline on how to get a TLC license quickly and easily.</h3>
            <div className={classes.section__contentSide__btnWrapper}>
              <Link to='/steps'>
                <button>Get started</button>
              </Link>
            </div>
        </div>

        <div className={classes.section__imageSide}>
            <img src={stepsImg} alt='StepsBySteps'></img>
        </div>

    </div>
  )
}

export default StepsSection