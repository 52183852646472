import React, { useState, useMemo, useEffect } from "react";

import classes from "./ListingList.module.scss";
import Spinner from "./../Spinner";
import ListingCard from "./../listing/ListingCard";
import Pagination from "../pagination/Pagination";

const ListingList = ({ isLoading, listings, resetPage, setResetPage }) => {
  const PageSize = 20;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    console.log(resetPage)
    if (resetPage) {
      setCurrentPage(1);
      setResetPage();
    }
  }, [resetPage, setResetPage])

  const pageListings = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return listings.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, listings]);

  return (
    <div className={classes.container}>
      {isLoading && <Spinner />}

      {!isLoading &&
        listings.length !== 0 &&
        pageListings.map((listing) => (
          <ListingCard key={listing.listing_id} listing={listing} mode={"listing"}/>
        ))}

      {!isLoading && listings.length !== 0 && (
        <Pagination
          // className="pagination-bar"
          currentPage={currentPage}
          totalCount={listings.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}

      {!isLoading && listings.length === 0 && (
        <p className={classes.noListingBlock}>Sorry, listings not found</p>
      )}
    </div>
  );
};

export default ListingList;
