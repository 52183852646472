import React from "react";
import classes from "./StepContent.module.scss";

import dlImage from "../../images/steps/idCard.svg";
import ddcImage from "../../images/steps/DDC.svg";
import applicationImage from "../../images/steps/application.svg";
import medicalImage from "../../images/steps/medical.svg";
import schoolImage from "../../images/steps/school.svg";
import drugTestImage from "../../images/steps/drugTest.svg";
import fingerPrintImage from "../../images/steps/fingerPrint.svg";
import fingerPrint from "../../images/steps/fingerScreenShot.png";
import examImage from "../../images/steps/practiceTest.svg";
import { Link } from "react-router-dom";
import GetInTouch from "../home/GetInTouch";

const StepContent = ({ currentStep, setStep }) => {
  return (
    <div className={classes.container}>
      {/* STEP 1 */}
      {currentStep === 1 && (
        <div>
          <h2>Step 1 - Upgrade your License </h2>

          <div className={classes.container_bloks}>
            <div className={classes.container_bloks_left}>
              <img src={dlImage} alt="DL img" />

              <h3>Requirements</h3>

              <ul>
                <li>Minimum age: 19</li>
                <li>Have a Valid DMV license (not permit)</li>
                <li>Valid Social Security Number</li>
                <li>No fines or fees owed to NYC DMV or TLC</li>
                <li>
                  No more than 5 points on your DMV license within a 15 month
                  period back.
                </li>
              </ul>
            </div>

            <div className={classes.container_bloks_right}>
              <p>
                You must upgrade your existing NYC license to a Class E license.
                This is necessary for driving taxis (even your own personal car
                for taxi purposes) in New York. This may cost around $21 to $28,
                depending on how old your driver's license is and your age. You
                will need to fill out{" "}
                <strong>
                  {" "}
                  <a href="https://dmv.ny.gov/files/mv44pdf" target="blank">
                    {" "}
                    MV-44
                  </a>
                </strong>{" "}
                form in order to change class license. While at the DMV, if your
                driver's license is going to expire soon, you should go through
                the process of license renewal.
              </p>

              <p>
                If in the last 3 years you had a license from another state, you
                will need to get an Abstract letter from the state you used to
                have a license.
              </p>

              <div className={classes.container_bloks_right_btnWrapper}>
                <button className={classes.button} onClick={()=>{setStep(currentStep + 1)}}>Next &#8594;</button>
              </div>

            </div>
          </div>
        </div>
      )}

      {/* STEP 2 */}
      {currentStep === 2 && (
        <div>
          <h2>Step 2 - Take a Defensive Driving Course</h2>

          <div className={classes.container_bloks}>
            <div className={classes.container_bloks_left}>
              <img src={ddcImage} alt="DDC img" />

              <h3>Resources</h3>

              <ul>
                <li>
                  DDC is available in-person classrooms or online for your
                  convenience:{" "}
                  <a href="https://dmv.ny.gov/pirp/classroom" target="blank">
                    Approved Courses
                  </a>
                </li>
              </ul>
            </div>

            <div className={classes.container_bloks_right}>
              <p>
                You must have taken a Defensive Driving Course within the last 3
                years. New York's Defensive Driving Course is 6 hours long and
                is a New York State Department of Motor Vehicles authorized
                course providing knowledge and techniques for the safe operation
                of a motor vehicle. TLC requires all new driver applicants and
                existing drivers to complete a Defensive Driving Course.
              </p>
              <p>
                The course price may vary but is usually around $60. Once you
                complete the course, your completion will be reported to the DMV
                and you will receive an official certificate of completion in
                the mail, which you will need to submit with your TLC
                application.
              </p>
              <p>
                Temporary completion certificates will be accepted ONLY if they
                include the type of course, date of the course, hours of
                instruction, name and license number of an instructor and
                official ink stamp or seal from delivering agent.
              </p>
              
              <div className={classes.container_bloks_right_btnWrapper}>
                <button className={classes.button} onClick={()=>{setStep(currentStep - 1)}}>&#8592; Previous</button>
                <button className={classes.button} onClick={()=>{setStep(currentStep + 1)}}>Next &#8594;</button>
              </div>

            </div>
          </div>
        </div>
      )}

      {/* STEP 3 */}
      {currentStep === 3 && (
        <div>
          <h2>Step 3 - Submit Initial TLC Application</h2>

          <div className={classes.container_bloks}>
            <div className={classes.container_bloks_left}>
              <img src={applicationImage} alt="Application img" />

              <h3>Requirements</h3>

              <ul>
                <li>$252 application fee.</li>
                <li>~30 minutes to fill out application.</li>
              </ul>

              <h3>Resources</h3>

              <ul>
                <li>
                  License Applications, Renewals & Summonses (LARS):{" "}
                  <a href="https://www.nyc.gov/lars" target="blank">
                    Apply for new License
                  </a>
                </li>
              </ul>
            </div>

            <div className={classes.container_bloks_right}>
              <p>
                Go to{" "}
                <a href="https://www.nyc.gov/lars" target="blank">
                  LARS
                </a>{" "}
                and click <strong>"Apply for New license".</strong> Select the
                application type
                <strong> "driver". </strong> Then select license type{" "}
                <strong> "TLC Driver's License". </strong>
                While there, make sure to watch the 10-minute video on sex
                trafficking, as this is a <strong>required</strong> part of your
                application.
              </p>
              <p>
                Once the application is completed, print or screenshot a copy of
                the confirmation page to maintain a record of your application
                number.
              </p>
              <p>
                You have 90 days from the day you submit this application online
                to complete all requirements. If you do not, your application
                will be denied.
              </p>
              <p>
                <i>
                  <strong>*Note* </strong>Your application must be completed
                  within 20 minutes or your session will expire. Once your
                  session expires you will need to enter your information all
                  over again.
                </i>
              </p>

              <p>
                <i>
                  <strong>*Note* </strong>$252 application fee and it is
                  non-refundable, even if your application is denied.
                </i>
              </p>

              <div className={classes.container_bloks_right_btnWrapper}>
                <button className={classes.button} onClick={()=>{setStep(currentStep - 1)}}>&#8592; Previous</button>
                <button className={classes.button} onClick={()=>{setStep(currentStep + 1)}}>Next &#8594;</button>
              </div>

            </div>
          </div>
        </div>
      )}

      {/* STEP 4 */}
      {currentStep === 4 && (
        <div>
          <h2>Step 4 - Medical Exam</h2>

          <div className={classes.container_bloks}>
            <div className={classes.container_bloks_left}>
              <img src={medicalImage} alt="Medical img" />

              <h3>Resources</h3>

              <ul>
                <li>
                  <a
                    href="https://www.nyc.gov/assets/tlc/downloads/pdf/driver_medical_form_b.pdf"
                    target="blank"
                  >
                    Medical Exam Form
                  </a>
                </li>
                <li>
                  <a href="https://a156-nauf.nyc.gov/tlcup" target="blank">
                    TLC UP Portal
                  </a>
                </li>
              </ul>
            </div>

            <div className={classes.container_bloks_right}>
              <p>
                TLC requires that all drivers are{" "}
                <strong>fit to operate a vehicle safely.</strong> You will need
                to visit your primary healthcare provider and have them perform
                a physical exam. They will need to fill out and stamp the TLC
                Medical Exam report, which is available to download and print
                from the{" "}
                <a
                  href="https://www.nyc.gov/assets/tlc/downloads/pdf/driver_medical_form_b.pdf"
                  target="blank"
                >
                  TLC website
                </a>
                .
              </p>
              <p>
                You must submit the completed medical form to the TLC by
                attaching the form on the{" "}
                <a href="https://a156-nauf.nyc.gov/tlcup" target="blank">
                  TLC UP
                </a>{" "}
                portal.
              </p>
              <p>
                <i>
                  <strong>*Note* </strong>The date of your medical examination
                  cannot be more than 90 days before the date that you submit
                  your TLC application.
                </i>
              </p>

              <div className={classes.container_bloks_right_btnWrapper}>
                <button className={classes.button} onClick={()=>{setStep(currentStep - 1)}}>&#8592; Previous</button>
                <button className={classes.button} onClick={()=>{setStep(currentStep + 1)}}>Next &#8594;</button>
              </div>

            </div>
          </div>
        </div>
      )}

      {/* STEP 5 */}
      {currentStep === 5 && (
        <div>
          <h2>
            Step 5 - Take a Wheelchair Class and a 24-Hour Taxi School Course
          </h2>

          <div className={classes.container_bloks}>
            <div className={classes.container_bloks_left}>
              <img src={schoolImage} alt="Application img" />

              <h3>Requirements</h3>

              <ul>
                <li>$175 24-hour Taxi School course.</li>
                <li>$75 Taxi School Exam.</li>
                <li>~$75 WAV training class.</li>
              </ul>

              <h3>Resources</h3>

              <ul>
                <li>
                  <a
                    href="https://www.nyc.gov/assets/tlc/downloads/pdf/tlc_school_post_filing.pdf"
                    target="blank"
                  >
                    Tlc-Approved 24-hour Course Education Providers
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.nyc.gov/assets/tlc/downloads/pdf/wav_provider_list.pdf"
                    target="blank"
                  >
                    Tlc-Approved WAV Trainers
                  </a>
                </li>
              </ul>
            </div>

            <div className={classes.container_bloks_right}>
              <p>
                All TLC license applicants are required to complete a 24-hour
                Taxi School course and pass the school exam. The course covers
                the following topics: TLC rules and regulations, Vision Zero,
                safe driving skills and traffic rules, geography, customer
                service, and providing equitable service to all.
              </p>
              <p>
                In addition, in compliance with the Americans with Disabilities
                Act (ADA), TLC drivers are required to pass a Wheelchair
                Accessible Vehicle training class. It is about 3 hours long and
                upon completion, you will receive a wallet-sized certificate of
                completion.
              </p>
              <p>
                <i>
                  <strong>*NOTE* </strong>It is best to complete these classes
                  as soon as possible to prevent any delay in receiving your TLC
                  license.
                </i>
              </p>

              <div className={classes.container_bloks_right_btnWrapper}>
                <button className={classes.button} onClick={()=>{setStep(currentStep - 1)}}>&#8592; Previous</button>
                <button className={classes.button} onClick={()=>{setStep(currentStep + 1)}}>Next &#8594;</button>
              </div>

            </div>
          </div>
        </div>
      )}

      {/* STEP 6 */}
      {currentStep === 6 && (
        <div>
          <h2>Step 6 - Drug Test</h2>

          <div className={classes.container_bloks}>
            <div className={classes.container_bloks_left}>
              <img src={drugTestImage} alt="Application img" />

              <h3>Requirements</h3>

              <ul>
                <li>$32 fee.</li>
                <li>~30 minutes to fill out application.</li>
              </ul>

              <h3>Resources</h3>

              <ul>
                <li>
                  <a
                    href="https://www.nyc.gov/assets/tlc/downloads/pdf/new_app_drug_test_info.pdf"
                    target="blank"
                  >
                    Drug Test Locations
                  </a>
                </li>
              </ul>
            </div>

            <div className={classes.container_bloks_right}>
              <p>
                New TLC Driver License Applicants must be tested to show that
                they are not using illegal substances. You must go to a TLC
                approved drug test location to be tested. This requirement must
                be completed after submitting your TLC application.
              </p>

              <p>
                To visit a TLC authorized drug test location you can either:
                <ul>
                  <li>
                    Call LabCorp (800) 923-2624 to schedule an appointment.
                    Results take approximately 3 days.
                  </li>
                  <li>
                    Or be seen on a walk in basis at one of the seven locations
                    on the New Driver Applicant Drug Test Information
                  </li>
                </ul>
              </p>
              <p>
                <i>
                  <strong>*Notes* </strong> If you do not hear from TLC after
                  the test, then you passed and you do not have anything to
                  worry about. If you test positive for any drugs, TLC will mail
                  you a letter letting you know that your application has been
                  denied.
                </i>
              </p>

              <div className={classes.container_bloks_right_btnWrapper}>
                <button className={classes.button} onClick={()=>{setStep(currentStep - 1)}}>&#8592; Previous</button>
                <button className={classes.button} onClick={()=>{setStep(currentStep + 1)}}>Next &#8594;</button>
              </div>

            </div>
          </div>
        </div>
      )}

      {/* STEP 7 */}
      {currentStep === 7 && (
        <div>
          <h2>Step 7 - Have your fingerprints and photo taken</h2>

          <div className={classes.container_bloks}>
            <div className={classes.container_bloks_left}>
              <img src={fingerPrintImage} alt="Application img" />

              <h3>Requirements</h3>

              <ul>
                <li>~$90 fee.</li>
              </ul>

              <h3>Resources</h3>

              <ul>
                <li>
                  <a
                    href="https://uenroll.identogo.com/workflows/15425Y"
                    target="blank"
                  >
                    IdentoGO - schedule an appointment
                  </a>
                </li>
              </ul>
            </div>

            <div className={classes.container_bloks_right}>
              <p>
                All TLC applicants should have fingerprints and photos taken by
                third-party company IdentoGO.
              </p>
              <p>
                Go to{" "}
                <a
                  href="https://uenroll.identogo.com/workflows/15425Y"
                  target="blank"
                >
                  IdentoGO
                </a>{" "}
                to schedule appointment and have your photo and fingerprint
                taken. Use service code <strong>15425Y</strong> (Taxi and
                Limousine Commission unique identifier).
              </p>
              <img
                className={classes.screenImage}
                src={fingerPrint}
                alt="finger-print"
              />

              <div className={classes.container_bloks_right_btnWrapper}>
                <button className={classes.button} onClick={()=>{setStep(currentStep - 1)}}>&#8592; Previous</button>
                <button className={classes.button} onClick={()=>{setStep(currentStep + 1)}}>Next &#8594;</button>
              </div>

            </div>
          </div>
        </div>
      )}

      {/* STEP 8 */}
      {currentStep === 8 && (
        <div>
          <h2>Step 8 - TLC Exam</h2>

          <div className={classes.container_bloks}>
            <div className={classes.container_bloks_left}>
              <img src={examImage} alt="Application img" />

              <h3>Requirements</h3>

              <ul>
                <li>~$50 fee.</li>
              </ul>

              <h3>Resources</h3>

              <ul>
                <li>
                  <a
                    href="https://uenroll.identogo.com/workflows/15425Y"
                    target="blank"
                  >
                    IdentoGO - schedule an appointment
                  </a>
                </li>

                <li>
                  <a
                    href="https://test-takers.psiexams.com/nyctlc"
                    target="blank"
                  >
                    PSI - schedule TLC Exam
                  </a>
                </li>
              </ul>
            </div>

            <div className={classes.container_bloks_right}>
              <p>
                <i>
                  <strong>*Note*</strong> DriveTLC highly recommends to you
                  practice as much as you can. We can help you with that. Just
                  visit our <Link to="/exam"> practice exam page</Link>.
                </i>
              </p>
              <p>
                Congratulations! You have completed the TLC Driver Education
                course and can now take the TLC Driver License Exam at a PSI
                test center in the Bronx, Brooklyn, Manhattan, Queens, or Staten
                Island.
              </p>
              <p>
                You must contact{" "}
                <a
                  href="https://test-takers.psiexams.com/nyctlc"
                  target="blank"
                >
                  PSI
                </a>{" "}
                to schedule an appointment to take the TLC Driver License Exam.
                TLC will not make the appointment for you.{" "}
              </p>
              <h2>Still, have some questions?</h2>
              <GetInTouch />
              
              <div className={classes.container_bloks_right_btnWrapper}>
                <button className={classes.button} onClick={()=>{setStep(currentStep - 1)}}>&#8592; Previous</button>
              </div>

            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StepContent;
