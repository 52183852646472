import React, { useEffect, useContext, useState } from "react";
import axios from "axios";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import ENV from "../../../env";
import CreateSetupIntentForm from "./CreateSetupIntent";

import classes from "./PopupSetupIntent.module.scss";
import { AccountContext } from "../../auth/account";

const PopupSetupIntent = (props) => {

  const { getSession } = useContext(AccountContext);

  const [isLoading, setIsLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const [stripePromise, setStripePromise] = useState("");

  useEffect(() => {
    getSession()
      .then((session) => {
        axios
          .post(ENV.BASE_URL + "/payments/create-setup-intent", null, {
            headers: { Authorization: `Bearer ${session.idToken.jwtToken}` },
          })
          .then((response) => {
            setClientSecret(response.data.data["client-secret"]);
            setStripePromise(loadStripe(response.data.data["publishable-key"]));

            setIsLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [getSession]);

  const options = {
    // passing the client secret obtained in step 2
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  return (
    <div className={classes.popupBox}>
      {!isLoading && (
        <div className={classes.popupBox__box}>
          <Elements stripe={stripePromise} options={options}>
            <CreateSetupIntentForm
              handleClose={props.handleClose}
              fromURL={props.fromURL}
              state={props.state}
            />
          </Elements>
        </div>
      )}
    </div>
  );
};

export default PopupSetupIntent;
