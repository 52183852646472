import React, {useState, useContext} from "react";
import { Link } from "react-router-dom";
import UserPool from '../auth/user-pool';
import axios from 'axios';
import ENV from "../../env";

import classes from './login.module.scss';
import {AiOutlineEyeInvisible, AiOutlineEye} from 'react-icons/ai';
import {BiLeftArrowAlt} from 'react-icons/bi';
// import {RiFacebookFill} from 'react-icons/ri';
import {AiOutlineGoogle} from 'react-icons/ai';

import { toast } from "react-toastify";

import {AccountContext} from '../auth/account';
import {CognitoUser} from 'amazon-cognito-identity-js';
import { useNavigate } from 'react-router-dom';

function Login() {
  const navigate = useNavigate();
  const {authenticate} = useContext(AccountContext);

  const [mode, setMode] = useState('login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [loginBtnSubmited, setLoginBtnSubmited] = useState(false);
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [showConfirmationCodeError, setShowConfirmationCodeError] = useState(false);
  const [fullName, setFullName] = useState('');
  const [showFullNameError, setShowFullNameError] = useState(false);
  const [signUpBtnSubmited, setSignUpBtnSubmited] = useState(false);

  // Function is called by clicking on sign up button
  const onSubmitSignUp = async event => {
    event.preventDefault();
    
    // Double check if email is valid
    if(!email.length || !validateEmail(email)){
      setShowEmailError(true);
      return;
    }
    
    // Double check password
    if(!password.length || !validatePassword(password)){
      setShowPasswordError(true);
      return;
    }
    
    // Check if email exist in DB
    const emailExist = await checkEmailInDB(email);
    if (emailExist) {
      toast.error("Email address already taken!", {
        position: "top-center",
        autoClose: 3500,
        theme: "colored",
      });
      setShowEmailError(true);
      return;
    }

    // Sign Up user
    UserPool.signUp(email, password, [], null, (err, data)=>{
      setSignUpBtnSubmited(true);

      if (err) {
        if (err.name === "UsernameExistsException"){
          authenticate(email, password)
            .catch(err => {
                // Resend confirmation code if user is not confirmed
                if (err.name === "UserNotConfirmedException") {
                  setMode('confirm_sign_up');
                  resubmitCode();
                } else {
                  // Show toast error email already exist
                  toast.error("Email address already taken!", {
                    position: "top-center",
                    autoClose: 3500,
                    theme: "colored",
                  });
                }
            });
          setSignUpBtnSubmited(false);
        }
      } else {
        // Create new user in DB
        axios({
          method: 'post',
          url: ENV.BASE_URL + "/users",
          data: {
            "user-id": data.userSub,
            "email": email,
            "full-name": fullName,
          }
        })
        .then(() => {
          // Move to confirmation section
          setMode('confirm_sign_up');
          setSignUpBtnSubmited(false);
        })
        .catch(function (error) {
          setSignUpBtnSubmited(false);
          toast.error(error.response.data.error, {
            position: "top-center",
            autoClose: 3500,
            theme: "colored",
          });
        });
      }
    });
  }

  // Request confirmation code
  const requestConfirmationCode = () => {
    const user = new CognitoUser({Username : email, Pool: UserPool});
    user.forgotPassword({
      onSuccess: () =>{
        // console.log("onSuccess", data);
      },

      onFailure: err => {
        console.log("OnFailer", err);
        // Show toast message with error
        toast.error(err.message, {
          position: "top-center",
          autoClose: 1500,
          theme: "colored",
        });
      },

      inputVerificationCode: () => {
        // Show confirmation code input
        setForgotPasswordMode(true);

        // Show success message
        toast.success("Code sent to " + email, {
          position: "top-center",
          autoClose: 2000,
          theme: "colored",
        });
      }
    })
  }

  // Function is called when user request to resend confirmation code
  const resubmitCode = () => {
    const user = new CognitoUser({Username : email, Pool: UserPool});

    user.resendConfirmationCode(function(err, result) {
      if (err) {
        // Show toast message with error
        toast.error(err.message, {
          position: "top-center",
          autoClose: 1500,
          theme: "colored",
        });
        return;
      }
      // Show toast message with destination email
      toast.success("Code sent to " + result.CodeDeliveryDetails.Destination, {
        position: "top-center",
        autoClose: 2000,
        theme: "colored",
      });
    });
  }

  // Function is called to confirm registration after sign up
  const onSubmitRegistrationCode = event => {
    event.preventDefault();

    const user = new CognitoUser({Username : email, Pool: UserPool});

    user.confirmRegistration(confirmationCode, true, (err, data) =>{
      if (err) {
          setShowConfirmationCodeError(true);
          return;
      }

      // Login user
      authenticate(email, password)
      .then(data => {
        // Change user status in DB to confirmed
        axios({
          method: 'PATCH',
          url: ENV.BASE_URL + "/users",
          headers: { Authorization: `Bearer ${data.idToken.jwtToken}` },
          data: {
            "status": "CONFIRMED"
          }
        })
        .then(() => {
          // Send email when new user is created
          console.log("Sending confirmation email ...");
          const today = new Date(Date.now());

          axios.post(ENV.BASE_EMAIL_URL + "/emails/send-email", {
            template_name: "new_user_confirmation.html",
            data: {
              date: today.toLocaleDateString(),
              email: email,
            },
            subject: "New Account Created",
            recipients: ['info.drivetlc@gmail.com'],
          }).then( () => {
            navigate('/');
            window.location.reload(false);
          }).catch(() => {
            navigate('/');
            window.location.reload(false);
          });

        })
        .catch((error) => {
          console.log(error.response.data.error);
        });
      })
      .catch(err => {
        // Show toast message with error
        toast.error(err.message, {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
        });
      });
    });
  }

  // Function is called when user request to send confirmation code
  const onSendConfirCodeForForgotPass = async event =>{
    event.preventDefault();

    // Double check if email is valid
    if(!email.length || !validateEmail(email)){
      setShowEmailError(true);
      return;
    }
    
    // Check if email exist in DB
    const emailExist = await checkEmailInDB(email);
    if (!emailExist) {
      toast.error("Email address does not exist!", {
        position: "top-center",
        autoClose: 3500,
        theme: "colored",
      });
      setShowEmailError(true);
      return;
    }

    // Send confirmation code
    requestConfirmationCode();
  }

  // Function is called when user request to reset password
  const onResetPassword = event => {
    event.preventDefault();

    //  Validate confirmation code
    if(!confirmationCode.match(/^[0-9]*$/)) {
      setShowConfirmationCodeError(true);
      return;
    }
    
    // Double check if password match to pattern
    if(!password.length || !validatePassword(password)){
      setShowPasswordError(true);
      return;
    }

    const user = new CognitoUser({Username : email, Pool: UserPool});
    
    user.confirmPassword(confirmationCode, password, {
      onSuccess: () => {
        // Login user
        authenticate(email, password)
        .then(() => {
            if (showConfirmationCodeError) {setShowConfirmationCodeError(false)}
            if (forgotPasswordMode) {setForgotPasswordMode(false)}
            navigate('/');
            window.location.reload(false);
        })
        .catch(err => {
            // Show error message if authentication failed
            toast.error(err.message, {
              position: "top-center",
              autoClose: 2000,
              theme: "colored",
            });
        });
      },
      onFailure: () => {
        // Show error message if confirmation code is invalid
        setShowConfirmationCodeError(true);
      }
    })
  }

  // Helper function to validate email
  const validateEmail = (email) => {
    if (!email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9_-]+\.[A-Za-z]+$/)) {
      // Check if email is empty
      if(!email.length){
        return true;
      }
      return false;
    }
    return true;
  }

  // Helper function to validate password
  const validatePassword = (password) => {
    if(!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,}$/)){
      // Check if password is empty
      if(!password.length){
        return true;
      }
      return false;
    }
    return true;
  }

  // Function is called when user click on login button
  const onSubmitLogIn = event => {
    event.preventDefault();
    setLoginBtnSubmited(true);

    // Double check if email and password are valid
    if(!email.length || !validateEmail(email)){
      setShowEmailError(true);
      setLoginBtnSubmited(false);
      return;
    }

    if(!password.length || !validatePassword(password)){
      setShowPasswordError(true);
      setLoginBtnSubmited(false);
      return;
    }
    
    // Login user
    authenticate(email, password)
      .then(() => {
        setLoginBtnSubmited(false);
        navigate('/');
        window.location.reload(false);
      })
      .catch(err => {
        if(err.code === "UserNotConfirmedException"){
          resubmitCode();
          
          // Confirmation code flow
          setMode('confirm_sign_up');
          setLoginBtnSubmited(false);
          return;
        }
          // Show toast error message from AWS
          toast.error(err.message, {
            position: "top-center",
            autoClose: 1500,
            theme: "colored",
          });
          setPassword("");
          setLoginBtnSubmited(false);
      });
    
    }
  
  // Function to initiate login with Google
  const loginWithGoogle = () => {
    const googleURL = "https://tlc.auth.us-east-1.amazoncognito.com/oauth2/authorize?redirect_uri="
      + `${ENV.BASE_UI_URL}`
      // + "http://localhost:3000"
      + "/auth&response_type=code&client_id="
      + `${ENV.COGNITO_GOOGLE_CLIENT_ID}`
      + "&state=Google" 
      + "&identity_provider=Google&scope=openid+profile+aws.cognito.signin.user.admin";

    window.location.href = googleURL;
  }

  // Function to initiate login with Facebook
  // const loginWithFacebook = () => {
  //   const googleURL = "https://tlc.auth.us-east-1.amazoncognito.com/oauth2/authorize?redirect_uri="
  //     + `${ENV.BASE_UI_URL}`
  //     // + "http://localhost:3000"
  //     + "/auth&response_type=code&client_id="
  //     + `${ENV.COGNITO_FACEBOOK_CLIENT_ID}`
  //     + "&state=Facebook" 
  //     + "&identity_provider=Facebook&scope=email+openid+aws.cognito.signin.user.admin";

  //   window.location.href = googleURL;
  // }

  // Helper function to check if email exist in DB
  const checkEmailInDB = async (email) => {
    const emailResponse =  await axios.post(ENV.BASE_URL + "/users/email-check", {
      email: email,
    })
    return emailResponse.data?.email_exist;
  }

  return (
    <div className={classes.container}>
      <div className={classes.container__form}>
        {/* Login flow */}
        {mode === 'login' && <>
          <h1>LOGIN</h1>
          
          <form onSubmit={onSubmitLogIn}>
            <input 
              className={showEmailError ? classes.errorInput : ""}
              type="email"
              placeholder="Email"
              required
              value={email}
              onChange={event => setEmail(event.target.value)}
              onBlur={() => {
                !validateEmail(email) ? setShowEmailError(true) : setShowEmailError(false);
              }}
            />

            {showEmailError && <p className={classes.errorMsg}>Invalid Email</p>}

            <div className={classes.passwordInputContainer}>
              <input 
                className={showPasswordError ? classes.errorInput : ""}
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                required
                minLength={6}
                value={password}
                onChange={event => setPassword(event.target.value)}
                onBlur={() => {
                  !validatePassword(password) ? setShowPasswordError(true) : setShowPasswordError(false);
                }}
              />

              {showPassword && <AiOutlineEyeInvisible
                className={classes.eyeIcon}
                onClick={() => setShowPassword(showPassword => !showPassword)}
              />}

              {!showPassword && <AiOutlineEye
                className={classes.eyeIcon}
                onClick={() => setShowPassword(showPassword => !showPassword)}
              />}
            </div>

            {showPasswordError && <p className={classes.errorMsg}>Password should contain numbers, upper and lower characters.</p>}

            {loginBtnSubmited ? <p>Loading...</p> : <input type="submit" value="LOGIN" /> }

          </form>
          
          <div className={classes.forgotPasswordWrraper}>
            <p onClick={() => setMode('forgot_password')}>Forgot password?</p>
          </div>

          <div className={classes.orWrapper}>
            <div className={classes.orLine}></div>
            <p>OR</p>
            <div className={classes.orLine}></div>
          </div>

          <div className={classes.sosialMediaWrapper}>
              <AiOutlineGoogle 
                className={classes.sosialMediaIcon}
                onClick={loginWithGoogle} />
              
              {/* FIXME: This feature is required Facebook Business account */}
              {/* <RiFacebookFill
                className={classes.sosialMediaIcon}
                onClick={loginWithFacebook} /> */}
          </div>

          <div className={classes.newUserWrapper}>
            <p>New user? <span
              className={classes.underline}
              onClick={() => setMode('sign_up')}>SIGN UP</span>
            </p>
          </div>
        </>}

        {/* Forgot Password flow */}
        {mode === 'forgot_password' && <>
          <h1>
            <BiLeftArrowAlt 
              className={classes.backArrow}
              onClick={() => {
                  setMode('login');
                  setForgotPasswordMode(false);
                }} />
            Forgot Password
          </h1>

          {!forgotPasswordMode &&
            <form onSubmit={onSendConfirCodeForForgotPass}>
              <input
                className={showEmailError ? classes.errorInput : ""}
                type="email"
                placeholder="Email"
                required
                value={email}
                onChange={event => setEmail(event.target.value)}
                onBlur={() => {
                  !validateEmail(email) ? setShowEmailError(true) : setShowEmailError(false);
                }}
              />

              {showEmailError && <p className={classes.errorMsg}>Invalid Email</p>}

              <input type="submit" value="SEND CODE" />
            </form>
          }

          {forgotPasswordMode && 
            <form onSubmit={onResetPassword}>
              <input
                className={showConfirmationCodeError ? classes.errorInput : ""}
                type="number"
                value={confirmationCode}
                placeholder="Confirmation code"
                onChange={event => {if(event.target.value.length <= 6) {setConfirmationCode(event.target.value)}}}
                onBlur={() => {if (showConfirmationCodeError) {setShowConfirmationCodeError(false)}}}
              />

              {showConfirmationCodeError && <p className={classes.errorMsg}>Invalid Confirmation Code</p>}

              <div className={classes.passwordInputContainer}>
                <input 
                  className={showPasswordError ? classes.errorInput : ""}
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  required
                  minLength={6}
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                  onBlur={() => {
                    !validatePassword(password) ? setShowPasswordError(true) : setShowPasswordError(false);
                  }}
                />

                {showPassword && <AiOutlineEyeInvisible 
                  className={classes.eyeIcon}
                  onClick={() => setShowPassword(showPassword => !showPassword)}
                />}

                {!showPassword && <AiOutlineEye
                  className={classes.eyeIcon}
                  onClick={() => setShowPassword(showPassword => !showPassword)}
                />}
              </div>

              {showPasswordError && <p className={classes.errorMsg}>Password should contain numbers, upper and lower characters.</p>}

              <input type="submit" value="SET PASSWORD" />

              <div className={classes.forgotPasswordWrraper}>
                <p onClick={() => requestConfirmationCode()}>Resend code</p>
              </div>
            </form>
          }
          </>
        }

        {/* Sign Up flow */}
        {mode === 'sign_up' && <>
          <h1>SIGN UP</h1>

          <form onSubmit={onSubmitSignUp} >
            <input 
              type="text"
              className={showFullNameError ? classes.errorInput : ""}
              value={fullName} 
              required
              placeholder="Joe Dow"
              onChange={event => setFullName(event.target.value)}
              onBlur={() => fullName.length < 2 ? setShowFullNameError(true) : setShowFullNameError(false)}
            />
            {showFullNameError && <p className={classes.errorMsg}>Name must be at least 2 characters.</p>}

            <input
              className={showEmailError ? classes.errorInput : ""}
              type="email"
              placeholder="Email"
              required
              value={email}
              onChange={event => setEmail(event.target.value)}
              onBlur={() => {
                !validateEmail(email) ? setShowEmailError(true) : setShowEmailError(false);
              }}
            />

            {showEmailError && <p className={classes.errorMsg}>Invalid Email</p>}

            <div className={classes.passwordInputContainer}>
              <input 
                className={showPasswordError ? classes.errorInput : ""}
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                required
                minLength={6}
                value={password}
                onChange={event => setPassword(event.target.value)}
                onBlur={() => {
                  !validatePassword(password) ? setShowPasswordError(true) : setShowPasswordError(false);
                }}
              />

              {showPassword && <AiOutlineEyeInvisible
                className={classes.eyeIcon}
                onClick={() => setShowPassword(showPassword => !showPassword)}
              />}

              {!showPassword && <AiOutlineEye
                className={classes.eyeIcon}
                onClick={() => setShowPassword(showPassword => !showPassword)}
              />}
            </div>

            {showPasswordError && <p className={classes.errorMsg}>Password should contain numbers, upper and lower characters.</p>}

            <div className={classes.termsAndConditions}>
              <p>By signing up, you agree to our <Link to="/terms">Terms & Conditions</Link> </p> 
            </div>
            
            {signUpBtnSubmited ? <p>Loading...</p> : <input type="submit" value="SIGN UP" /> }
          </form>
          
          <div className={classes.newUserWrapper}>
            <p>Already have an account? <span
              className={classes.underline}
              onClick={() => setMode('login')}>LOGIN</span>
            </p>
          </div>
        </>}

        {/* Sign Up confirmation flow */}
        {mode === 'confirm_sign_up' && <>
          <h1>
            <BiLeftArrowAlt 
              className={classes.backArrow}
              onClick={() => {
                  setMode('sign_up');
                  setConfirmationCode('');
                }} />
            Confirm Sign Up
          </h1>

          {/* Confirm registration process */}
          <form onSubmit={onSubmitRegistrationCode}>
            <input
              className={showConfirmationCodeError ? classes.errorInput : ""}
              type="number"
              value={confirmationCode}
              placeholder="Confirmation code"
              onChange={event => {if(event.target.value.length <= 6) {setConfirmationCode(event.target.value)}}}
              onBlur={() => {if (showConfirmationCodeError) {setShowConfirmationCodeError(false)}}}
            />

            {showConfirmationCodeError && <p className={classes.errorMsg}>Invalid Confirmation Code</p>}

            <input type="submit" value="CONFIRM"/>

            <div className={classes.forgotPasswordWrraper}>
              <p onClick={() => resubmitCode()}>Resend code</p>
            </div>
          </form>
          </>
        }
      </div>
    </div>
  );
}
  
  export default Login;
  