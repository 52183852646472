import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';

import './index.scss';

import App from './App';
import { Account } from './components/auth/account';
import store from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Account>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Account>
        </Provider>
    </React.StrictMode>
);