import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import ENV from "../../env";

import { AccountContext } from "../auth/account";

import classes from "./SavedTab.module.scss";
import Spinner from "../Spinner";
import SavedQuestionItem from "./SavedQuestionItem";

const SavedTab = () => {
  const { getSession } = useContext(AccountContext);
  const [userSavedQuestions, setUserSavedQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch user information
    getSession().then((session) => {
      axios
        .get(ENV.BASE_URL + "/exam/questions/saved", {
          headers: { Authorization: `Bearer ${session.idToken.jwtToken}` },
        })
        .then((response) => {
          // Check if request is success
          if (response.status === 200) {
            setUserSavedQuestions(response.data.data);
            setIsLoading(false);
          } 
        })
        .catch((err) => {
          if (err.response.data.error === "No saved questions" && err.response.status === 404) {
            setIsLoading(false);
          } else {
            console.log(err);
          }
        });
    });
  }, [getSession, setUserSavedQuestions]);

  // Delete question from user saved questi+on
  const deleteQuestion = (questionId) => {
    getSession().then((session) => {
      axios({
        method: "post",
        url: ENV.BASE_URL + "/exam/questions/save",
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
        },
        data: {
          question_id: questionId,
        },
      })
        .then(() => {
          setUserSavedQuestions((current) =>
            current.filter((element) => element.question_id !== questionId)
          );
        })
        .catch(function (error) {
          console.log("[ERROR: ], Save/remove question", error);
        });
    });
  };

  return (
    <div className={classes.container}>
      {/* Show loading spinner while fetching data */}
      {isLoading && <Spinner />}

      {!isLoading &&
        userSavedQuestions.map((question) => (
          <SavedQuestionItem
            key={question.record_id}
            handleDelete={() => {
              deleteQuestion(question.question_id);
            }}
            questionInfo={question}
          />
        ))}
    </div>
  );
};
export default SavedTab;
