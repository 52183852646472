import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import ENV from "../env";

import { useNavigate } from "react-router-dom";
import { AccountContext } from "../components/auth/account";
import DashboardSection from "../components/dashboard/DashboardSection";
import Spinner from "../components/Spinner";

const Dashboard = () => {
  const { getSession } = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState("");
  const [userPaymentMethod, setPaymentMethod] = useState([]);
  const [userListings, setUserListings] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch user information
    getSession()
      .then((session) => {
        axios
          .get(ENV.BASE_URL + "/users", {
            headers: { Authorization: `Bearer ${session.idToken.jwtToken}` },
          })
          .then((response) => {
            // Check if request is success
            if (response.status === 200) {
              setUserData(response.data);

              // Get all user payment methods
              axios
                .get(ENV.BASE_URL + "/payments/payment-methods", {
                  headers: {
                    Authorization: `Bearer ${session.idToken.jwtToken}`,
                  },
                })
                .then((pmResponse) => {
                  if (pmResponse.status === 200) {
                    setPaymentMethod(pmResponse.data.data);
                  } else {
                    console.log(
                      "Payment API: Error - get all user payment methods"
                    );
                  }

                  axios
                    .get(ENV.BASE_URL + "/listing", {
                      headers: {
                        Authorization: `Bearer ${session.idToken.jwtToken}`,
                      },
                    })
                    .then((listings) => {
                      if (listings.status === 200) {
                        setUserListings(listings.data.data);
                      } else {
                        console.log(
                          "Listings API: Error - get all user listings"
                        );
                      }
                    });

                  // Disable spiner
                  setIsLoading(false);
                });
            } else {
              console.log("[ERROR]: Error happens while fetching user info.");
              navigate("/login");
            }
          });
      })
      .catch((err) => {
        console.log(err);
        navigate("/login");
      });
  }, [getSession, navigate]);

  return (
    <>
      {/* Show loading spinner while fetching data */}
      {isLoading && <Spinner />}

      {/* Show User dashboard */}
      {!isLoading && (
        <DashboardSection
          userData={userData}
          userPaymentMethods={userPaymentMethod}
          userListings={userListings}
        />
      )}
    </>
  );
};

export default Dashboard;
