import React, { useState, useEffect } from "react";

import classes from "./SettingsTab.module.scss";
import PopupSetupIntent from "./payments/PopupSetupIntent";
import CardItem from "./payments/CardItem";

const SettingsTab = ({ userPaymentMethods }) => {
  const [isPaymentMethods, setIsPaymentMethods] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    // Check user payment methods
    if (Object.keys(userPaymentMethods).length > 0) {
      setIsPaymentMethods(true);
    }
  }, [userPaymentMethods]);

  return (
    <div className={classes.container}>
      {isPopupOpen && (
        <PopupSetupIntent
          handleClose={() => {
            setIsPopupOpen(false);
          }}
          fromURL = "dashboard"
        />
      )}
      {/* TODO change password and add phone number*/}
      {/* <p>Change password</p> */}
      {/* <p>Add phone number</p> */}

      {/* Payments block */}
      {/* If payments not set up yet */}
      {!isPaymentMethods && <p>No payments method yet.</p>}

      {/* Payments already set up */}
      {isPaymentMethods && (
        <div className={classes.container_paymentsBlock}>
          <h3>Your Cards</h3>
          {userPaymentMethods.map((card) => (
            <CardItem key={card.id} cardInfo={card} />
          ))}
        </div>
      )}

      <button
        onClick={() => {
          setIsPopupOpen(true);
        }}
      >
        Add payment method
      </button>
    </div>
  );
};

export default SettingsTab;
