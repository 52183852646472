import React from 'react';
import Cart from '../components/cart/Cart';


const CartPage = () => {
  return (
    <Cart />
  )
}

export default CartPage