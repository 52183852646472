import React from 'react'

import classes from './CardItem.module.scss';

import logoVisa from '../../../images/card_logos/visa.jpeg';
import logoMasterCard from '../../../images/card_logos/masterCard.png'; 
import logoAmex from '../../../images/card_logos/amex.png';
import logoDiscover from '../../../images/card_logos/discover.png';
import logoUnionpay from '../../../images/card_logos/unionpay.png';

const CardItem = ({cardInfo}) => {
  return (
    <div className={classes.cardBlock}>
        <div className={classes.cardBlock_logoSection}>
          {cardInfo.brand === 'unionpay' && <img src={logoUnionpay} alt="Unionpay logo"/>}
          {cardInfo.brand === 'discover' && <img src={logoDiscover} alt="Discover logo"/>}
          {cardInfo.brand === 'amex' && <img src={logoAmex} alt="Amex logo"/>}
          {cardInfo.brand === 'visa' && <img src={logoVisa} alt="Visa logo"/>}
          {cardInfo.brand === 'mastercard' && <img src={logoMasterCard} alt="MasterCard logo"/>}
        </div>
        
        <div className={classes.cardBlock_cardInfoSection}>
          <p className={classes.firstLine}>**** {cardInfo.last4}</p>
          <p className={classes.secondLine}>{cardInfo.exp_month}/{cardInfo.exp_year}</p>
        </div>
    
    </div>
  )
}

export default CardItem