import React from 'react'

import classes from './Popup.module.scss';

const Popup = (props) => {
  return (
    <div className={classes.popupBox}>
        <div className={classes.popupBox__box}>

        {props.image && <div className={classes.popupBox__box__left}>
            <img src={props.image} alt="Images"/>
        </div>}
            <div className={classes.popupBox__box__right}>
                <h2>{props.title}</h2>
                
                <p>{props.content}</p>
                
                <button 
                    className={classes.popupBox__btnClose}
                    onClick={props.handleClose}>Continue</button>
            </div>
        </div>
    </div>
  )
}

export default Popup