import React from "react";

import classes from "./DashboardSection.module.scss";
import TabsSection from "./TabsSection";
import UserInfo from "./UserInfo";

const DashboardSection = (props) => {
  return (
    <div className={classes.section}>
      <UserInfo userData={props.userData} />
      <TabsSection
        userPaymentMethods={props.userPaymentMethods}
        userListings={props.userListings}
        userData={props.userData}
      />
    </div>
  );
};

export default DashboardSection;
